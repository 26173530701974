import _remove from 'lodash/remove';
import * as MunicipalityActions from '../actions/municipality';
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  municipalityList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  municipalityDetails: {
    isFetching: false,
    isFetched: false,
    municipalityData: null,
  },
  currentMunicipality: null,
};

const setMunicipalityListFetching = (fetching) => (state) => ({
  ...state,
  municipalityList: {
    ...state.municipalityList,
    isFetching: fetching,
  },
});

const setMunicipalityList = (state, { payload: { listData, total } }) => ({
  ...state,
  municipalityList: {
    ...state.municipalityList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setMunicipality = (state, { payload: { municipalityId } }) => ({
  ...state,
  currentMunicipality: state.municipalityList.listData
    .filter((municipality) => {
      return municipality.id == municipalityId;
    })
    .reduce((_, val) => val, null),
});

const setMunicipalityFetch = (state, { payload: { municipalityData } }) => ({
  ...state,
  municipalityDetails: {
    ...state.municipalityDetails,
    municipalityData: {
      ...municipalityData,
    },
  },
});

const setMunicipalityDeleted = (state, { payload: { municipalityId } }) => {
  const newListData = state.municipalityList.listData;
  _remove(newListData, { id: municipalityId });

  const newState = {
    ...state,
    municipalityList: {
      ...state.municipalityList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [MunicipalityActions.LIST_MUNICIPALITY_REQUEST]:
    setMunicipalityListFetching(true),
  [MunicipalityActions.LIST_MUNICIPALITY_SUCCESS]: setMunicipalityList,
  [MunicipalityActions.LIST_MUNICIPALITY_FAILURE]:
    setMunicipalityListFetching(false),
  [MunicipalityActions.SET_MUNICIPALITY]: setMunicipality,
  [MunicipalityActions.FETCH_MUNICIPALITY_SUCCESS]: setMunicipalityFetch,
  [MunicipalityActions.DELETE_MUNICIPALITY_SUCCESS]: setMunicipalityDeleted,
};

export default reducerWithActionMap(actionMap, initialState);


