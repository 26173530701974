import React, { useCallback, useEffect, useRef, useState } from "react";

const initialState = [];

const CommentMediaField = ({ field: { name, value }, setFieldValue }) => {
  const [fileState, setFileState] = useState(initialState);
  const fileUpload = useRef();

  const handleImageChange = useCallback((e) => {
    e.preventDefault();
    const newStates = [];
    for(i=0; i<e.target.files.length; i++){
      newStates.push({file: e.target.files[i], imagePreviewUrl: URL.createObjectURL(e.target.files[i])});
    }
    setFieldValue(name, e.target.files);
    setFileState(newStates);
  }, []);

  const handleReset = useEffect(() => {
    if (!value) {
      setFileState(initialState);
    }
  }, [value]);

  return (
    <div
      className={
        fileState.length !=0
          ? "h-32 flex flex-row justify-start items-start mt-8 md:mt-14 px-4 md:px-0"
          : "hidden"
      }
    >
      <input
        ref={fileUpload}
        type="file"
        multiple
        id={`file_${name}`}
        onChange={handleImageChange}
        className="file-upload"
      />
      {fileState.map((item) => (
        <img
          src={item.imagePreviewUrl}
          className=" h-32 object-contain"
        />
      ))}
    </div>
  );
};

export default CommentMediaField;
