import moment from "moment";
import { Link } from "react-router-dom";

import ProfileIcon from "../../img/profile_menu_gray.svg";

import "./ChatMessages.css";

const ChatMessages = ({ messages }) => {
  return (
    <div className="container messages-wrapper">
      {messages.map((message) => {
        const isUserAuthor = message.author_user;
        const imageUrl = isUserAuthor
          ? message.user.profilePicture
            ? message.user.profilePicture.url
            : null
          : message.municipal_advisor.profilePicture
          ? message.municipal_advisor.profilePicture.url
          : null;
        return (
          <div key={message.id} className="mb-20">
            <div className="flex items-left w-auto mt-4 lg:mt-0">
              <img
                src={
                  imageUrl
                    ? `${process.env.RAZZLE_API_URL}${imageUrl}`
                    : ProfileIcon
                }
                className="border-4 border-blue rounded-full w-20 h-20 object-cover"
                width="96"
                height="96"
              />
              <div className="ml-20">
                <p className="font-semibold text-2xl text-gray-700">
                  {isUserAuthor
                    ? `${message.user.firstName} ${message.user.familyName}`
                    : `${message.municipal_advisor.firstName} ${message.municipal_advisor.familyName}`}
                </p>
                <p className="font-normal text-xl text-gray-500">
                  {moment(message.created_at).format("D.MM.YYYY")}
                </p>
                <p className="content font-normal text-xl text-gray-400">
                  {message.content}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ChatMessages;
