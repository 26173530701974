export const SET_LANGUAGE = "LANGUAGE@SET_LANGUAGE";

export const setLanguage =
  ({ language }) =>
  (dispatch) => {
    console.log("language", language);
    dispatch({
      type: SET_LANGUAGE,
      payload: language,
    });
  };
