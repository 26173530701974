import { Field } from "formik";
import { useIntl } from "react-intl";
import { connect } from "react-redux";

import { getLanguage } from "../../middleware/selectors/language.js";
import DocumentWhite from "../../img/news_menu_whte.svg";
import MultipleDocumentField from "./MultipleDocumentField";
import { LANGUAGES } from "../../i18n/settings/languages.js";

const Docs = ({ fields, language }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {fields &&
        fields.map((fld) => {
          return (
            <div key={fld.key}>
              <div className="w-max-lg h-30 rounded-xl flex flex-col justify-left px-4 mt-8 md:px-0">
                <Field name={fld.fieldName}>
                  {({ field, form: { setFieldValue } }) => (
                    <MultipleDocumentField
                      field={field}
                      setFieldValue={setFieldValue}
                      id={fld.fieldName}
                    />
                  )}
                </Field>
              </div>
              <div className="mt-5 relative">
                <label
                  htmlFor={fld.fieldName}
                  className="text-white text-sm md:text-lg md:mt-4"
                >
                  <div className="flex flex-row w-400 bg-blue text-white my-4 px-4 py-4 rounded-xl hover:bg-opacity-80 cursor-pointer">
                    <img
                      src={DocumentWhite}
                      width="28"
                      height="auto"
                      className="mr-2"
                    />
                    <div className="px-4">
                      <p className="text-2xl font-bold">
                        {formatMessage({ id: "attachEnrollment" })}
                      </p>
                      <p className="text-xl font-normal">
                        {language == LANGUAGES.MK
                          ? fld.fieldLabel
                          : fld.fieldLabel_sq ?? fld.fieldLabel}
                      </p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          );
        })}
    </>
  );
};

const mapStateToProps = (state) => ({
  language: getLanguage(state),
});

export default connect(mapStateToProps)(Docs);
