// get axios
import getAxiosInstance from "../config/http";

export const LIST_CATEGORY_REQUEST = "CATEGORY@LIST_CATEGORY_REQUEST";
export const LIST_CATEGORY_SUCCESS = "CATEGORY@LIST_CATEGORY_SUCCESS";
export const LIST_CATEGORY_FAILURE = "CATEGORY@LIST_CATEGORY_FAILURE";

export const listCategories = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_CATEGORY_REQUEST,
  });
  try {
    const categoryData = await http.get("/categories", { params });
    dispatch({
      type: LIST_CATEGORY_SUCCESS,
      payload: {
        listData: categoryData.data,
      },
    });
    return categoryData.data;
  } catch (e) {
    dispatch({
      type: LIST_CATEGORY_FAILURE,
    });
  }
};

export const CREATE_CATEGORY_REQUEST = "CATEGORY@CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CATEGORY@CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAILURE = "CATEGORY@CREATE_CATEGORY_FAILURE";

export const createCategory = (file, categoryData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_CATEGORY_REQUEST,
  });
  try {
    const formData = new FormData();
    formData.append("files.featuredImage", file);
    formData.append("data", JSON.stringify(categoryData));
    const { data } = await http.post("/categories", formData);
    dispatch({
      type: CREATE_CATEGORY_SUCCESS,
      payload: {
        categoryData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: CREATE_CATEGORY_FAILURE,
    });
    throw e;
  }
};

export const FETCH_CATEGORY_REQUEST = "CATEGORY@FETCH_CATEGORY_REQUEST";
export const FETCH_CATEGORY_SUCCESS = "CATEGORY@FETCH_CATEGORY_SUCCESS";
export const FETCH_CATEGORY_FAILURE = "CATEGORY@FETCH_CATEGORY_FAILURE";

export const fetchCategory = (categoryId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_CATEGORY_REQUEST,
  });
  try {
    const categoryData = await http.get(`/categories/${categoryId}`);
    dispatch({
      type: FETCH_CATEGORY_SUCCESS,
      payload: {
        categoryData: categoryData.data,
      },
    });
    return categoryData.data;
  } catch (e) {
    dispatch({
      type: FETCH_CATEGORY_FAILURE,
    });
  }
};

export const UPDATE_CATEGORY_REQUEST = "CATEGORY@UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "CATEGORY@UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "CATEGORY@UPDATE_CATEGORY_FAILURE";

export const updateCategory = (id, file, categoryData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_CATEGORY_REQUEST,
  });
  try {
    const formData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("files.featuredImage", file);
    formData.append(
      "data",
      JSON.stringify({
        ...categoryData,
      })
    );
    const data = await http.put(`/categories/${id}`, formData);
    dispatch({
      type: UPDATE_CATEGORY_SUCCESS,
      payload: {
        categoryData: data,
      },
    });
  } catch (e) {
    dispatch({
      type: UPDATE_CATEGORY_FAILURE,
    });
  }
};

export const DELETE_CATEGORY_REQUEST = "CATEGORY@DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "CATEGORY@DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "CATEGORY@DELETE_CATEGORY_FAILURE";

export const deleteCategory = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_CATEGORY_REQUEST,
  });
  try {
    const data = await http.delete(`/categories/${id}`);
    dispatch({
      type: DELETE_CATEGORY_SUCCESS,
      payload: {
        categoryId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: DELETE_CATEGORY_FAILURE,
    });
  }
};
