import getAxiosInstance from "../config/http";

export const LIST_SUBVENTION_CLAIM_REQUEST =
  "SUBVENTION@LIST_SUBVENTION_CLAIM_REQUEST";
export const LIST_SUBVENTION_CLAIM_SUCCESS =
  "SUBVENTION@LIST_SUBVENTION_CLAIM_SUCCESS";
export const LIST_SUBVENTION_CLAIM_FAILURE =
  "SUBVENTION@LIST_SUBVENTION_CLAIM_FAILURE";

export const listSubventionClaims = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_SUBVENTION_CLAIM_REQUEST,
  });
  try {
    const subventionRequestData = await http.get("/subvention-requests", {
      params,
    });
    dispatch({
      type: LIST_SUBVENTION_CLAIM_SUCCESS,
      payload: {
        listData: subventionRequestData.data,
      },
    });
    return subventionRequestData.data;
  } catch (e) {
    dispatch({
      type: LIST_SUBVENTION_CLAIM_FAILURE,
    });
  }
};

export const checkUnviewedRequests = async () => {
  const http = getAxiosInstance();

  try {
    const requestsData = await http.get("/subvention-requests?_sort=viewed:ASC&_limit=1");
    return requestsData.data.some(request => request.viewed === false);
  } catch(e) {
    console.error("Error fetching data", e);
    return false;
  }
}

export const checkUnviewedRequestsForSubvention = async (subventionID) => {
  const http = getAxiosInstance();

  try {
    const requestsData = await http.get(`/subvention-requests?subvention=${subventionID}&_sort=viewed:ASC&_limit=1`);
    return requestsData.data.some(request => request.viewed === false);
  } catch(e) {
    console.error("Error fetching data", e);
    return false;
  }
}

export const COUNT_SUBVENTION_CLAIM_REQUEST =
  "SUBVENTION@COUNT_SUBVENTION_CLAIM_REQUEST";
export const COUNT_SUBVENTION_CLAIM_SUCCESS =
  "SUBVENTION@COUNT_SUBVENTION_CLAIM_SUCCESS";
export const COUNT_SUBVENTION_CLAIM_FAILURE =
  "SUBVENTION@COUNT_SUBVENTION_CLAIM_FAILURE";

export const countSubventionClaims = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: COUNT_SUBVENTION_CLAIM_REQUEST,
  });
  try {
    const subventionRequestData = await http.get("/subvention-requests/count", {
      params,
    });
    dispatch({
      type: COUNT_SUBVENTION_CLAIM_SUCCESS,
      payload: {
        total: subventionRequestData.data,
      },
    });
    return subventionRequestData.data;
  } catch (e) {
    dispatch({
      type: COUNT_SUBVENTION_CLAIM_FAILURE,
    });
  }
};

export const FETCH_SUBVENTION_CLAIM_REQUEST =
  "SUBVENTION@FETCH_SUBVENTION_CLAIM_REQUEST";
export const FETCH_SUBVENTION_CLAIM_SUCCESS =
  "SUBVENTION@FETCH_SUBVENTION_CLAIM_SUCCESS";
export const FETCH_SUBVENTION_CLAIM_FAILURE =
  "SUBVENTION@FETCH_SUBVENTION_CLAIM_FAILURE";

export const fetchSubventionClaim = (subventionClaimId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_SUBVENTION_CLAIM_REQUEST,
  });
  try {
    const subventionRequestData = await http.get(
      `/subvention-requests/${subventionClaimId}`
    );
    dispatch({
      type: FETCH_SUBVENTION_CLAIM_SUCCESS,
      payload: {
        subventionRequestData: subventionRequestData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: FETCH_SUBVENTION_CLAIM_FAILURE,
    });
  }
};

export const UPDATE_SUBVENTION_CLAIM_REQUEST =
  "SUBVENTION@UPDATE_SUBVENTION_CLAIM_REQUEST";
export const UPDATE_SUBVENTION_CLAIM_SUCCESS =
  "SUBVENTION@UPDATE_SUBVENTION_CLAIM_SUCCESS";
export const UPDATE_SUBVENTION_CLAIM_FAILURE =
  "SUBVENTION@UPDATE_SUBVENTION_CLAIM_FAILURE";

export const updateSubventionClaim = (id, subventionRequestData) => async (
  dispatch
) => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_SUBVENTION_CLAIM_REQUEST,
  });
  try {
    const data = await http.put(
      `/subvention-requests/${id}`,
      subventionRequestData
    );
    dispatch({
      type: UPDATE_SUBVENTION_CLAIM_SUCCESS,
      payload: {
        subventionRequestData: data,
      },
    });
  } catch (e) {
    dispatch({
      type: UPDATE_SUBVENTION_CLAIM_FAILURE,
    });
  }
};

export const DELETE_SUBVENTION_CLAIM_REQUEST =
  "SUBVENTION@DELETE_SUBVENTION_CLAIM_REQUEST";
export const DELETE_SUBVENTION_CLAIM_SUCCESS =
  "SUBVENTION@DELETE_SUBVENTION_CLAIM_SUCCESS";
export const DELETE_SUBVENTION_CLAIM_FAILURE =
  "SUBVENTION@DELETE_SUBVENTION_CLAIM_FAILURE";

export const deleteSubventionClaim = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_SUBVENTION_CLAIM_REQUEST,
  });
  try {
    const data = await http.delete(`/subvention-requests/${id}`);
    dispatch({
      type: DELETE_SUBVENTION_CLAIM_SUCCESS,
      payload: {
        subventionClaimId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: DELETE_SUBVENTION_CLAIM_FAILURE,
    });
  }
};

export const CREATE_SUBVENTION_CLAIM_REQUEST =
  "SUBVENTION@CREATE_SUBVENTION_CLAIM_REQUEST";
export const CREATE_SUBVENTION_CLAIM_SUCCESS =
  "SUBVENTION@CREATE_SUBVENTION_CLAIM_SUCCESS";
export const CREATE_SUBVENTION_CLAIM_FAILURE =
  "SUBVENTION@CREATE_SUBVENTION_CLAIM_FAILURE";

export const createSubventionClaim = (claimData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_SUBVENTION_CLAIM_REQUEST,
  });
  try {
    const { data } = await http.post(`/subvention-requests`, claimData);
    dispatch({
      type: CREATE_SUBVENTION_CLAIM_SUCCESS,
      payload: {
        subventionClaimId: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: CREATE_SUBVENTION_CLAIM_FAILURE,
    });
  }
};

export const MARK_SUBVENTION_AS_VIEWED_REQUEST = "SUBVENTION@MARK_SUBVENTION_AS_VIEWED_REQUEST";
export const MARK_SUBVENTION_AS_VIEWED_SUCCESS = "SUBVENTION@MARK_SUBVENTION_AS_VIEWED_SUCCESS";
export const MARK_SUBVENTION_AS_VIEWED_FAILURE = "SUBVENTION@MARK_SUBVENTION_AS_VIEWED_FAILURE";

export const markSubventionAsViewed = (id) => async(dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: MARK_SUBVENTION_AS_VIEWED_REQUEST,
  });
  try {
    await http.put(`/subvention-requests/${id}/viewed`, { viewed: true });
    dispatch({
      type: MARK_SUBVENTION_AS_VIEWED_SUCCESS,
      payload: {
        id,
      },
    });
  } catch(e) {
    dispatch({
      type: MARK_SUBVENTION_AS_VIEWED_FAILURE,
      payload: {
        error: e.message,
      }
    })
  }
};
