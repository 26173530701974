import _remove from "lodash/remove";
import * as MessageActions from "../actions/chatMessage";
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  messageList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  chatUserList: {
    isFetching: false,
    isFetched: false,
    listData: [],
  },
  messageDetails: {
    isFetching: false,
    isFetched: false,
    messageData: null,
  },
};

const setMessageListFetching = (fetching) => (state) => ({
  ...state,
  messageList: {
    ...state.messageList,
    isFetching: fetching,
  },
});

const setMessageList = (state, { payload: { listData, total } }) => ({
  ...state,
  messageList: {
    ...state.messageList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setChatUserListFetching = (fetching) => (state) => ({
  ...state,
  chatUserList: {
    ...state.chatUserList,
    isFetching: fetching,
  },
});

const setChatUserList = (state, { payload: { listData } }) => ({
  ...state,
  chatUserList: {
    ...state.chatUserList,
    listData,
    isFetching: false,
    isFetched: true,
  },
});

const setMessageFetching = (fetching) => (state) => ({
  ...state,
  messageDetails: {
    ...state.messageDetails,
    isFetching: fetching,
  },
});

const setMessage = (state, { payload: { messageData } }) => ({
  ...state,
  messageDetails: {
    ...state.messageDetails,
    messageData,
    isFetching: false,
    isFetched: true,
  },
});

const setMessageDeleted = (state, { payload: { messageId } }) => {
  const newListData = state.messageList.listData;
  _remove(newListData, { id: messageId });

  const newState = {
    ...state,
    messageList: {
      ...state.messageList,
      listData: newListData,
    },
  };
  return newState;
};

const appendMessage = (state, { payload: { message } }) => {
  return {
    ...state,
    messageList: {
      ...state.messageList,
      listData: [...state.messageList.listData, message],
    },
  };
};

const actionMap = {
  [MessageActions.LIST_MESSAGE_REQUEST]: setMessageListFetching(true),
  [MessageActions.LIST_MESSAGE_SUCCESS]: setMessageList,
  [MessageActions.LIST_MESSAGE_FAILURE]: setMessageListFetching(false),
  [MessageActions.DELETE_MESSAGE_SUCCESS]: setMessageDeleted,
  [MessageActions.FETCH_MESSAGE_REQUEST]: setMessageFetching(true),
  [MessageActions.FETCH_MESSAGE_SUCCESS]: setMessage,
  [MessageActions.FETCH_MESSAGE_FAILURE]: setMessageFetching(false),
  [MessageActions.CREATE_MESSAGE_SUCCESS]: appendMessage,
  [MessageActions.LIST_CHAT_USERS_REQUEST]: setChatUserListFetching(true),
  [MessageActions.LIST_CHAT_USERS_SUCCESS]: setChatUserList,
  [MessageActions.LIST_CHAT_USERS_FAILURE]: setChatUserListFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);
