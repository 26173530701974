import { intl } from "../utils/translate";

const FieldLabel = ({ label, isRequired }) => (
  <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
    {intl(label)}
    {isRequired ? "*" : ""}
  </span>
);

export default FieldLabel;
