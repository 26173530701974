import getAxiosInstance from "../config/http";

export const CREATE_MESSAGE_REQUEST = "MESSAGE@CREATE_MESSAGE_REQUEST";
export const CREATE_MESSAGE_SUCCESS = "MESSAGE@CREATE_MESSAGE_SUCCESS";
export const CREATE_MESSAGE_FAILURE = "MESSAGE@CREATE_MESSAGE_FAILURE";

export const createMessage = (messageData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_MESSAGE_REQUEST,
  });
  try {
    const { data } = await http.post("/chat-messages", messageData);
    dispatch({
      type: CREATE_MESSAGE_SUCCESS,
      payload: {
        message: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: CREATE_MESSAGE_FAILURE,
    });
    throw e;
  }
};

export const LIST_MESSAGE_REQUEST = "MESSAGE@LIST_MESSAGE_REQUEST";
export const LIST_MESSAGE_SUCCESS = "MESSAGE@LIST_MESSAGE_SUCCESS";
export const LIST_MESSAGE_FAILURE = "MESSAGE@LIST_MESSAGE_FAILURE";

export const listMessages = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_MESSAGE_REQUEST,
  });
  try {
    const messageData = await http.get("/chat-messages", { params });
    dispatch({
      type: LIST_MESSAGE_SUCCESS,
      payload: {
        listData: messageData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: LIST_MESSAGE_FAILURE,
    });
  }
};

export const LIST_CHAT_USERS_REQUEST = "MESSAGE@LIST_CHAT_USERS_REQUEST";
export const LIST_CHAT_USERS_SUCCESS = "MESSAGE@LIST_CHAT_USERS_SUCCESS";
export const LIST_CHAT_USERS_FAILURE = "MESSAGE@LIST_CHAT_USERS_FAILURE";

export const listChatUsers = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_CHAT_USERS_REQUEST,
  });
  try {
    const users = await http.get(
      `/chat-messages/users/${params.municipal_advisor}`
    );
    dispatch({
      type: LIST_CHAT_USERS_SUCCESS,
      payload: {
        listData: users.data,
      },
    });
  } catch (e) {
    dispatch({
      type: LIST_CHAT_USERS_FAILURE,
    });
  }
};

export const FETCH_MESSAGE_REQUEST = "MESSAGE@FETCH_MESSAGE_REQUEST";
export const FETCH_MESSAGE_SUCCESS = "MESSAGE@FETCH_COMMNENT_SUCCESS";
export const FETCH_MESSAGE_FAILURE = "MESSAGE@FETCH_MESSAGE_FAILURE";

export const fetchMessage = (messageId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_MESSAGE_REQUEST,
  });
  try {
    const messageData = await http.get(`/chat-messages/${messageId}`);
    dispatch({
      type: FETCH_MESSAGE_SUCCESS,
      payload: {
        messageData: messageData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: FETCH_MESSAGE_FAILURE,
    });
  }
};

export const DELETE_MESSAGE_REQUEST = "MESSAGE@DELETE_MESSAGE_REQUEST";
export const DELETE_MESSAGE_SUCCESS = "MESSAGE@DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_FAILURE = "MESSAGE@DELETE_MESSAGE_FAILURE";

export const deleteMessage = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_MESSAGE_REQUEST,
  });
  try {
    const data = await http.delete(`/chat-messages/${id}`);
    dispatch({
      type: DELETE_MESSAGE_SUCCESS,
      payload: {
        messageId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: DELETE_MESSAGE_FAILURE,
    });
  }
};
