import React from 'react';
import { connect } from 'react-redux';
import { isModalOpen } from "../middleware/selectors/modal";
import { closeModal } from "../middleware/actions/modal";

const WithConnectedModal = (id, ChildComponent) => {
  const WithConnectedModalComponent = ({
    isOpen,
    closeModal,
    ...props
  }) => {
    return <ChildComponent isOpen={isOpen} closeModal={closeModal.bind(null, id)} {...props} />;
  };

  const mapStateToProps = (state) => ({
    isOpen: isModalOpen(state, id),
  });

  const mapDispatchToProps = {
    closeModal
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithConnectedModalComponent);
};

export default WithConnectedModal;
