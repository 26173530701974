import React from "react";
import moment from "moment";

const NotificationCard = ({ notification }) => {
  const { title, body, created_at, user } = notification;
  return (
    <>
      <div className="bg-white max-w-full mx-auto px-18 py-9 rounded-xl shadow-xl">
        <div className="font-semibold mb-2">{title}</div>
        <div className="mb-2">{moment(created_at).format("DD.MM.YYYY")}</div>
        <div>{body}</div>
      </div>
    </>
  );
};

export default NotificationCard;
