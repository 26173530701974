import getAxiosInstance from '../config/http';

export const LIST_MUNICIPALITY_REQUEST = 'MUNICIPALITY@LIST_MUNICIPALITY_REQUEST';
export const LIST_MUNICIPALITY_SUCCESS = 'MUNICIPALITY@LIST_MUNICIPALITY_SUCCESS';
export const LIST_MUNICIPALITY_FAILURE = 'MUNICIPALITY@LIST_MUNICIPALITY_FAILURE';

export const listMunicipalities = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_MUNICIPALITY_REQUEST,
  });
  try {
    const municipalityData = await http.get("/municipalities", { params });
    dispatch({
      type: LIST_MUNICIPALITY_SUCCESS,
      payload: {
        listData: municipalityData.data,
      },
    });
    return municipalityData.data;
  } catch (e) {
    dispatch({
      type: LIST_MUNICIPALITY_FAILURE,
    });
  }
};

export const CREATE_MUNICIPALITY_REQUEST =
  "MUNICIPALITY@CREATE_MUNICIPALITY_REQUEST";
export const CREATE_MUNICIPALITY_SUCCESS =
  "MUNICIPALITY@CREATE_MUNICIPALITY_SUCCESS";
export const CREATE_MUNICIPALITY_FAILURE =
  "MUNICIPALITY@CREATE_MUNICIPALITY_FAILURE";

export const createMunicipality =
  (file, municipalityData) => async (dispatch) => {
    const http = getAxiosInstance();
    dispatch({
      type: CREATE_MUNICIPALITY_REQUEST,
    });
    try {
      const formData = new FormData();
      formData.append("files.featuredImage", file);
      formData.append("data", JSON.stringify(municipalityData));
      const { data } = await http.post("/municipalities", formData);
      dispatch({
        type: CREATE_MUNICIPALITY_SUCCESS,
        payload: {
          municipalityData: data,
        },
      });
      return data;
    } catch (e) {
      dispatch({
        type: CREATE_MUNICIPALITY_FAILURE,
      });
      throw e;
    }
  };

export const FETCH_MUNICIPALITY_REQUEST =
  "MUNICIPALITY@FETCH_MUNICIPALITY_REQUEST";
export const FETCH_MUNICIPALITY_SUCCESS =
  "MUNICIPALITY@FETCH_MUNICIPALITY_SUCCESS";
export const FETCH_MUNICIPALITY_FAILURE =
  "MUNICIPALITY@FETCH_MUNICIPALITY_FAILURE";

export const fetchMunicipality = (municipalityId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_MUNICIPALITY_REQUEST,
  });
  try {
    const municipalityData = await http.get(
      `/municipalities/${municipalityId}`
    );
    dispatch({
      type: FETCH_MUNICIPALITY_SUCCESS,
      payload: {
        municipalityData: municipalityData.data,
      },
    });
    return municipalityData.data;
  } catch (e) {
    dispatch({
      type: FETCH_MUNICIPALITY_FAILURE,
    });
  }
};

export const UPDATE_MUNICIPALITY_REQUEST =
  "MUNICIPALITY@UPDATE_MUNICIPALITY_REQUEST";
export const UPDATE_MUNICIPALITY_SUCCESS =
  "MUNICIPALITY@UPDATE_MUNICIPALITY_SUCCESS";
export const UPDATE_MUNICIPALITY_FAILURE =
  "MUNICIPALITY@UPDATE_MUNICIPALITY_FAILURE";

export const updateMunicipality =
  (id, file, municipalityData) => async (dispatch) => {
    const http = getAxiosInstance();
    dispatch({
      type: UPDATE_MUNICIPALITY_REQUEST,
    });
    try {
      const formData = new FormData();
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      formData.append("files.logo", file);
      formData.append(
        "data",
        JSON.stringify({
          ...municipalityData,
        })
      );
      const data = await http.put(`/municipalities/${id}`, formData);
      dispatch({
        type: UPDATE_MUNICIPALITY_SUCCESS,
        payload: {
          municipalityData: data,
        },
      });
    } catch (e) {
      dispatch({
        type: UPDATE_MUNICIPALITY_FAILURE,
      });
    }
  };

export const DELETE_MUNICIPALITY_REQUEST =
  "MUNICIPALITY@DELETE_MUNICIPALITY_REQUEST";
export const DELETE_MUNICIPALITY_SUCCESS =
  "MUNICIPALITY@DELETE_MUNICIPALITY_SUCCESS";
export const DELETE_MUNICIPALITY_FAILURE =
  "MUNICIPALITY@DELETE_MUNICIPALITY_FAILURE";

export const deleteMunicipality = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_MUNICIPALITY_REQUEST,
  });
  try {
    const data = await http.delete(`/municipalities/${id}`);
    dispatch({
      type: DELETE_MUNICIPALITY_SUCCESS,
      payload: {
        municipalityId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: DELETE_MUNICIPALITY_FAILURE,
    });
  }
};

export const SET_MUNICIPALITY = "MUNICIPALITY@SET_MUNICIPALITY";

export const setMunicipality =
  ({ municipalityId }) =>
  async (dispatch) => {
    dispatch({
      type: SET_MUNICIPALITY,
      payload: {
        municipalityId,
      },
    });
  };

