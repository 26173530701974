import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import findImageWithQuality from "../../utils/findImageWithQuality";
import { LANGUAGES } from "../../i18n/settings/languages";

const MyRequestCard = ({ request, language }) => {
  const { title, title_sq, created_at, featuredImage } = request.subvention;

  let currentTime = moment(created_at);

  return (
    <>
      <Link
        to={`/subventionRequest/${request.id}`}
        className="block bg-white max-w-md w-full px-4 py-18 rounded-xl shadow-card"
      >
        <div className="flex flex-col">
          <div className="flex flex-col justify-center">
            <h4 className="text-sm md:text-base font-bold uppercase my-2">
              {language == LANGUAGES.MK ? title : title_sq ?? title}
            </h4>
            <span className="text-lightGray2 text-base">
              {currentTime.format("DD.MM.YYYY")}
            </span>
          </div>

          <img
            className="h-52 w-full object-cover"
            src={`${process.env.RAZZLE_API_URL}${findImageWithQuality(
              featuredImage,
              "small"
            )}`}
          />
        </div>
      </Link>
    </>
  );
};
export default MyRequestCard;
