import _remove from "lodash/remove";
import * as EnrolmentActions from "../actions/enrolment";
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  enrolmentsList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  enrolmentDetails: {
    isFetching: false,
    isFetched: false,
    enrolmentData: null,
  },
};

const setEnrolmentListFetching = (fetching) => (state) => ({
  ...state,
  enrolmentList: {
    ...state.enrolmentList,
    isFetching: fetching,
  },
});

const setEnrolmentList = (state, { payload: { listData, total } }) => ({
  ...state,
  enrolmentList: {
    ...state.enrolmentList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setEnrolmentFetching = (fetching) => (state) => ({
  ...state,
  enrolmentDetails: {
    ...state.enrolmentDetails,
    isFetching: fetching,
  },
});

const setEnrolment = (state, { payload: { enrolmentData } }) => ({
  ...state,
  enrolmentDetails: {
    ...state.enrolmentDetails,
    enrolmentData,
    isFetching: false,
    isFetched: true,
  },
});

const actionMap = {
  [EnrolmentActions.LIST_ENROLMENT_REQUEST]: setEnrolmentListFetching(true),
  [EnrolmentActions.LIST_ENROLMENT_SUCCESS]: setEnrolmentList,
  [EnrolmentActions.LIST_ENROLMENT_FAILURE]: setEnrolmentListFetching(false),
  [EnrolmentActions.FETCH_ENROLMENT_REQUEST]: setEnrolmentFetching(true),
  [EnrolmentActions.FETCH_ENROLMENT_SUCCESS]: setEnrolment,
  [EnrolmentActions.FETCH_ENROLMENT_FAILURE]: setEnrolmentFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);
