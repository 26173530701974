import getAxiosInstance from "../config/http";

export const LIST_SUBVENTION_CATEGORY_REQUEST = 'SUBVENTION_CATEGORY@LIST_SUBVENTION_CATEGORY_REQUEST';
export const LIST_SUBVENTION_CATEGORY_SUCCESS = 'SUBVENTION_CATEGORY@LIST_SUBVENTION_CATEGORY_SUCCESS';
export const LIST_SUBVENTION_CATEGORY_FAILURE = 'SUBVENTION_CATEGORY@LIST_SUBVENTION_CATEGORY_FAILURE';

export const listSubventionsCategories = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_SUBVENTION_CATEGORY_REQUEST,
  });
  try {
    const subventionData = await http.get('/subvention-categories', {params});
    dispatch({
      type: LIST_SUBVENTION_CATEGORY_SUCCESS,
      payload: {
        listData: subventionData.data,
      },
    });

    return subventionData.data;
  } catch (e) {
    dispatch({
      type: LIST_SUBVENTION_CATEGORY_FAILURE,
    });
    throw e;
  }
};

export const FETCH_SUBVENTION_CATEGORY_REQUEST = "SUBVENTION_CATEGORY@FETCH_SUBVENTION_CATEGORY_REQUEST";
export const FETCH_SUBVENTION_CATEGORY_SUCCESS = "SUBVENTION_CATEGORY@FETCH_SUBVENTION_CATEGORY_SUCCESS";
export const FETCH_SUBVENTION_CATEGORY_FAILURE = "SUBVENTION_CATEGORY@FETCH_SUBVENTION_CATEGORY_FAILURE";

export const fetchSubventionCategory = (subventionId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_SUBVENTION_CATEGORY_REQUEST,
  });
  try {
    const subventionData = await http.get(`/subventions/${subventionId}`);
    dispatch({
      type: FETCH_SUBVENTION_CATEGORY_SUCCESS,
      payload: {
        subventionData: subventionData.data,
      },
    });
    return subventionData.data;
  } catch (e) {
    dispatch({
      type: FETCH_SUBVENTION_CATEGORY_FAILURE,
    });
  }
};