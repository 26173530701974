import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";

import {
  setMunicipality,
  listMunicipalities,
} from "../middleware/actions/municipality";
import { setLanguage } from "../middleware/actions/language";
import { getMunicipalities } from "../middleware/selectors/municipality";
import logo from "../img/logo.svg";
import { LANGUAGES } from "../i18n/settings/languages";

const ChooseMunicipality = ({
  municipalities,
  listMunicipalities,
  setMunicipality,
}) => {
  const history = useHistory();

  const formSubmit = async (values) => {
    setMunicipality({ municipalityId: values.municipality });
    const currentMunicipality = municipalities.listData.filter(
      (m) => m.id == values.municipality
    );
    if (currentMunicipality[0].numberOfLanguages > 1) {
      history.push("/chooseLanguage");
    } else {
      setLanguage(LANGUAGES.MK);
      history.push("/home");
    }
  };
  const { formatMessage } = useIntl();
  const { listData } = municipalities;

  useEffect(() => {
    listMunicipalities();
  }, []);

  return (
    <div className="flex flex-col items-center container mx-auto mt-5">
      <figure>
        <img src={logo} alt="mZaednica logo" width="180" height="auto" />
      </figure>
      <Formik
        initialValues={{}}
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={formSubmit}
      >
        <Form>
          <div className="">
            <div className="mt-10">
              <div className="relative flex flex-col items-center justify-center">
                <label htmlFor="municipality" className="text-gray-700">
                  Одбери општина
                </label>
                <Field
                  as="select"
                  name="municipality"
                  className="w-48 text-lg md:text-xl text-F bg-transparent border-2 border-lightGray1 rounded-lg focus:border-lightGray1 focus:bg-transparent focus:ring-0 pl-0 md:pl-3 py-0"
                  placeholder="..."
                >
                  <option value=""></option>
                  {listData &&
                    listData.map((municipality) => (
                      <option value={municipality.id} key={municipality.id}>
                        {municipality.name}
                      </option>
                    ))}
                </Field>
              </div>
            </div>
            <div className="w-48 mt-16 mb-20 block mx-auto m-0 text-center">
              <button
                type="submit"
                className="py-2 px-4 flex justify-center items-center bg-green hover:bg-green focus:green-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
              >
                Продолжи
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => ({
  municipalities: getMunicipalities(state),
});

const mapDispatchToProps = {
  setMunicipality,
  listMunicipalities,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseMunicipality);
