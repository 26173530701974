import { useIntl } from "react-intl";


import React from "react";
import moment from "moment";
import ProcessOrange from "../../img/process_orange.svg";
import FinishedOrange from "../../img/finished_orange.svg";
import avatar from "../../img/nophoto.jpg";
import { Link } from "react-router-dom";
import findImageWithQuality from "../../utils/findImageWithQuality";
import { LANGUAGES } from "../../i18n/settings/languages";

const SubventionCard = ({ subvention }) => {
  const {
    id,
    title,
    title_sq,
    featuredImage,
    dueDate,
    created_at,
    municipality,
    language,
  } = subvention;

  var now = moment(new Date());
  var end = moment(dueDate);
  var days = end.diff(now, "days");

  const { formatMessage } = useIntl();

  return (
    <>
      <Link
        to={`/subventionDetails/${id}`}
        className="block bg-white max-w-md w-full px-4 py-18 rounded-xl shadow-card"
      >
        <div className="flex">
          <div className="mr-3.5 flex-shrink-0">
            <img
              src={`${process.env.RAZZLE_API_URL}${findImageWithQuality(
                municipality.logo
              )}`}
              alt="Logo"
              className="border-4 border-orange rounded-full w-10 h-10 md:w-16 md:h-16"
            />
          </div>
          <div className="flex flex-col justify-center">
            <h5 className="text-lg font-semibold">
              {formatMessage({ id: "municipality" })}{" "}
              {language === LANGUAGES.MK
                ? municipality.name
                : municipality.name_sq}
            </h5>
            <p className="text-darkGray1 text-base align-middle">
              {moment(created_at).format("DD.MM.YYYY")} &#8226;{" "}
              {days <= 0 ? (
                <img
                  src={FinishedOrange}
                  alt=""
                  className="w-5 h-5 inline-block align-middle"
                />
              ) : (
                <img
                  src={ProcessOrange}
                  alt=""
                  className="w-5 h-5 inline-block align-middle"
                />
              )}
              <span className="text-orange font-bold">
                {days <= 0
                  ? formatMessage({ id: "unactive" })
                  : formatMessage({ id: "active2" })}
              </span>
            </p>
            <h4 className="text-sm md:text-base font-bold uppercase my-2">
              {language === LANGUAGES.MK ? title : title_sq ?? title}
            </h4>
          </div>
        </div>
        {featuredImage && 
        <img
          className="h-52 w-full object-cover"
          src={
            !featuredImage
              ? avatar
              : `${process.env.RAZZLE_API_URL}${findImageWithQuality(
                  featuredImage,
                  "small"
                )}`
          }
          alt=""
          />}
      </Link>
    </>
  );
};
export default SubventionCard;
