import _remove from "lodash/remove";
import _reduce from "lodash/reduce";
import * as IssueActions from "../actions/issue";
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  issuesList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  supportedIssuesList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  reportSupports: {
    supportedMap: {},
  },
  issueDetails: {
    isFetching: false,
    isFetched: false,
    issueData: null,
  },
  counts: {

  }
};

const setIssueListFetching = (fetching) => (state) => ({
  ...state,
  issuesList: {
    ...state.issuesList,
    isFetching: fetching,
  },
});

const setIssueList = (state, { payload: { listData, total } }) => ({
  ...state,
  issuesList: {
    ...state.issuesList,
    listData,
    isFetching: false,
    isFetched: true,
  },
});

const setTotalIssues = (state, { payload: { total, countType } }) => {
  if(!countType){

    return ({
      ...state,
      issuesList: {
        ...state.issuesList,
        total
      }
    });
  } else {

    return ({
      ...state,
      counts: {
        ...state.counts,
        [countType]: total
      }
    });
  }
};

const setSupportedIssueListFetching = (fetching) => (state) => ({
  ...state,
  supportedIssuesList: {
    ...state.supportedIssuesList,
    isFetching: fetching,
  },
});

const setSupportedIssueList = (state, { payload: { listData, total } }) => ({
  ...state,
  supportedIssuesList: {
    ...state.supportedIssuesList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setListSupports = (state, { payload: { supports } }) => ({
  ...state,
  reportSupports: {
    supportedMap: _reduce(
      supports,
      (acc, curr) => {
        acc[curr.report.id] = curr;
        return acc;
      },
      {}
    ),
  },
});

const setIssueFetching = (fetching) => (state) => ({
  ...state,
  issueDetails: {
    ...state.issueDetails,
    isFetching: fetching,
  },
});

const setIssue = (state, { payload: { issueData } }) => ({
  ...state,
  issueDetails: {
    ...state.issueDetails,
    issueData,
    isFetching: false,
    isFetched: true,
  },
});

const setIssueDeleted = (state, { payload: { issueId } }) => {
  const newListData = state.issuesList.listData;
  _remove(newListData, { id: issueId });

  const newState = {
    ...state,
    issuesList: {
      ...state.issuesList,
      listData: newListData,
    },
  };
  return newState;
};

const addCreatedSupport = (state, { payload: { supportData } }) => {
  const supportedMap = {
    ...state.reportSupports.supportedMap,
    [supportData.report.id]: supportData,
  };
  const newState = {
    ...state,
    reportSupports: {
      ...state.reportSupports,
      supportedMap,
    },
  };
  return newState;
};

const removeDeletedSupport = (state, { payload: { supportData } }) => {
  const supportedMap = {
    ...state.reportSupports.supportedMap
  };
  delete supportedMap[supportData.report.id];
  const newState = {
    ...state,
    reportSupports: {
      ...state.reportSupports,
      supportedMap,
    },
  };
  return newState;
}

const actionMap = {
  [IssueActions.LIST_ISSUE_REQUEST]: setIssueListFetching(true),
  [IssueActions.LIST_ISSUE_SUCCESS]: setIssueList,
  [IssueActions.LIST_ISSUE_FAILURE]: setIssueListFetching(false),
  [IssueActions.LIST_SUPPORTED_ISSUE_REQUEST]:
    setSupportedIssueListFetching(true),
  [IssueActions.LIST_SUPPORTED_ISSUE_SUCCESS]: setSupportedIssueList,
  [IssueActions.LIST_SUPPORTED_ISSUE_FAILURE]:
    setSupportedIssueListFetching(false),
  [IssueActions.DELETE_ISSUE_SUCCESS]: setIssueDeleted,
  [IssueActions.FETCH_ISSUE_REQUEST]: setIssueFetching(true),
  [IssueActions.FETCH_ISSUE_SUCCESS]: setIssue,
  [IssueActions.FETCH_ISSUE_FAILURE]: setIssueFetching(false),
  [IssueActions.COUNT_ISSUE_SUCCESS]: setTotalIssues,
  [IssueActions.LIST_SUPPORTS_SUCCESS]: setListSupports,
  [IssueActions.CREATE_SUPPORT_SUCCESS]: addCreatedSupport,
  [IssueActions.REMOVE_SUPPORT_SUCCESS]: removeDeletedSupport,
};

export default reducerWithActionMap(actionMap, initialState);
