import { useIntl } from "react-intl";


import React from "react";
import { Link } from "react-router-dom";
import FbLogo from "../img/fb.svg";
import InstagramLogo from "../img/insta.svg";
import Mail from "../img/mail.svg";
import Usaidlogo from "../img/usaid.png";


const Footer = () => {
  const {formatMessage} = useIntl();

  return (
    <>
      <section className="bg-footer">
        <footer className="container pt-60 pb-8 px-5 lg:px-0">
          <div className="flex justify-center space-x-8 flex-wrap">
            <figure>
              <a href="https://www.facebook.com/mZaednica" target="_blank">
                <img
                  src={FbLogo}
                  className="w-12 h-12 hover:scale-90	transform"
                />
              </a>
            </figure>
            <figure>
              <a
                href="https://www.instagram.com/mzaednica/?fbclid=IwAR1LHCzHJ7KdHFEHev-BifEnhz30X1VZntL4yu73ogXcW6WNsNncOSVvjUo"
                target="_blank"
              >
                <img
                  src={InstagramLogo}
                  className="w-12 h-12 hover:scale-90	transform"
                />
              </a>
            </figure>
            <figure>
              <a href="mailto:info@mZaednica.mk">
                <img src={Mail} className="w-12 h-12 hover:scale-90	transform" />
              </a>
            </figure>
          </div>

          <nav>
            <ul className="text-lightGray3 text-lg font-semibold md:flex md:space-x-8 justify-center mt-16">
              <li className="hover:text-white mb-2">
                <Link to="/about">  {formatMessage({id: "forMcommunity"})}</Link>
              </li>
              <li className="hover:text-white mb-2">
                <Link to="/privacyPolicy"> {formatMessage({id: "policyText"})}</Link>
              </li>
              <li className="hover:text-white mb-2">
                <Link to="/termsAndConditions"> {formatMessage({id: "termsUse"})}</Link>
              </li>
              <li className="hover:text-white mb-2">
                <Link to="/contact"> {formatMessage({id: "contacts"})}</Link>
              </li>
            </ul>
            <div className="border border-lightGray3 mt-12"></div>
          </nav>

          <div className="md:flex mt-3">
            <figure>
              <a href="https://www.usaid.gov/north-macedonia" target="_blank">
                <img
                  src={Usaidlogo}
                  width="103"
                  className="w-103 h-auto mr-12"
                />
              </a>
            </figure>
            <h6 className="text-white font-semibold flex-1">
            {formatMessage({id: "footerUSA"})}
            </h6>
          </div>
        </footer>
      </section>
    </>
  );
};
export default Footer;
