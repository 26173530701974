import React from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { useIntl } from "react-intl";

import logo from "../img/logo.svg";
import { connect } from "react-redux";
import { openModal } from "../middleware/actions/modal";
import { LOGIN_MODAL_ID, SIGNUP_MODAL_ID } from "../consts/modalIds";

import ReportMenu from "../img/report_menu_blue.svg";
import Dropdown from "../components/Dropdown";
import HeaderNavLink from "./HeaderNavLink";
import NewsMenu from "../img/news_menu_blue.svg";
import NotificationsMenu from "../img/notifications_menu_blue.svg";
import EnrolmentsMenu from "../img/enrolments_menu_blue.svg";
import MyAdvisorMenu from "../img/myAdvisor-01.svg";
import ProfileIcon from "../img/profile_menu_gray.svg";
import { getUserDetails } from "../middleware/selectors/user";

const items = [
  {
    name: "reports",
    path: "/reports",
    icon: ReportMenu,
    id: "reports",
  },
  {
    name: "news",
    path: "/news",
    icon: NewsMenu,
    id: "news",
  },
  {
    name: "notifications",
    path: "/notifications",
    icon: NotificationsMenu,
    id: "notifications",
  },
  {
    name: "services",
    path: "/services",
    icon: EnrolmentsMenu,
    id: "services",
  },
  {
    name: "myAdvisor",
    path: "/advisors",
    icon: MyAdvisorMenu,
    id: "myIdvisor",
  },
];

const Header = ({ openModal, userDetails, nobg }) => {
  const { isFetching, isFetched, userData } = userDetails;
  const { formatMessage } = useIntl();
  return (
    <div
      className={
        nobg
          ? ""
          : "sticky top-0 bg-nav bg-left-top bg-no-repeat	bg-cover shadow-card z-50"
      }
    >
      <header className="container px-5 lg:px-0">
        <nav className="md:flex flex-wrap justify-between items-center py-8 px-0 md:px-6">
          <figure>
            <Link to="/home">
              <img src={logo} alt="mZaednica logo" width="180" height="auto" />
            </Link>
          </figure>
          {!isFetching && !isFetched && (
            <div className="flex items-center w-auto">
              <button
                href="#"
                className="w-auto flex items-center justify-center border border-transparent text-base font-semibold rounded-xl text-darkGray1 bg-lightGray1 hover:bg-white py-2 px-7 mt-8 md:mt-0 focus:outline-none mr-5"
                onClick={openModal.bind(null, LOGIN_MODAL_ID, null)}
              >
                {formatMessage({ id: "signin" })}
              </button>
              <button
                href="#"
                className="w-auto flex items-center justify-center border border-transparent text-base font-semibold rounded-xl text-darkGray1 bg-lightGray1 hover:bg-white py-2 px-7 mt-8 md:mt-0 focus:outline-none"
                onClick={openModal.bind(null, SIGNUP_MODAL_ID, null)}
              >
                {formatMessage({ id: "register" })}
              </button>
            </div>
          )}
          {isFetched && userData && (
            <>
              <div className="flex flex-wrap sm:justify-center md:items-center w-auto space-x-10 mt-4 md:mt-0">
                {items.map((item) => {
                  return (
                    <HeaderNavLink
                      key={item.id}
                      to={!item.subItems && item.path}
                      className="text-white font-bold inline-flex flex-col items-center"
                      activeClassName="text-blue font-bold"
                    >
                      {(isActive) => (
                        <>
                          <SVG
                            src={item.icon}
                            alt=""
                            className="h-8 mb-3"
                            width="30"
                            height="auto"
                            preProcessor={(code) =>
                              isActive
                                ? code
                                : code.replace(/fill=".*?"/g, 'fill="#FFF"')
                            }
                          />
                          <span className="text-base">
                            {formatMessage({ id: item.name })}
                          </span>
                        </>
                      )}
                    </HeaderNavLink>
                  );
                })}
              </div>
              {/* TODO --- Language toggle to be removed after adding the landing page for choosing language */}
              {/* <LanguageToggle language="en" location={location} /> */}
              <div className="flex items-center justify-center w-auto space-x-3 mt-4 lg:mt-0">
                <Link to="/profile">
                  <span className="font-extrabold text-base text-white">
                    {userData.username}
                  </span>
                </Link>
                <Link to="/profile">
                  <img
                    src={
                      userData.profilePicture && userData.profilePicture.url
                        ? `${process.env.RAZZLE_API_URL}${userData.profilePicture.url}`
                        : ProfileIcon
                    }
                    className="border-2 rounded-full w-16 h-16 object-cover"
                    width="64"
                    height="64"
                  />
                </Link>
                <Dropdown />
              </div>
            </>
          )}
        </nav>
      </header>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDetails: getUserDetails(state),
});

const mapDispatchToProps = {
  openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
