export const getEnrolmentRequests = (state) =>
  state.enrolmentRequest.enrolmentRequestList;
export const getEnrolmentRequestDetails = (state) =>
  state.enrolmentRequest.enrolmentRequestDetails;
export const getCancelationFlow = (state) =>
  state.enrolmentRequest.cancelationFlow;
export const getEnrolmentRequestMeeting = (state) =>
  state.enrolmentRequest.meeting;
export const getSubmitionType = (state) =>
  state.enrolmentRequest.docsSubmitionType;
export const getChosenDate = (state) => state.enrolmentRequest.chosenDate;
