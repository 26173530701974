import { useIntl } from "react-intl";

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ReactMarkdown from "react-markdown";

import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";
import { fetchEducationalFacility } from "../../middleware/actions/educationalFacility";
import { getEducationalFacilityDetails } from "../../middleware/selectors/educationalFacility";
import ShareGray from "../../img/share_gray.svg";
import findImageWithQuality from "../../utils/findImageWithQuality";

const EnrolmentDetails = ({
  fetchEducationalFacility,
  educationalFacilityDetails,
}) => {
  let { id } = useParams();
  let details = educationalFacilityDetails.educationalFacilityData;

  useEffect(() => {
    if (!details || details.id != id) {
      fetchEducationalFacility(id);
    }
  }, []);

  const share = (e) => {
    e.preventDefault();
    if (navigator.share) {
      navigator
        .share({
          url: `${process.env.RAZZLE_WEB_URL}/newsDetails/${id}`,
          // extra, optional options
          fbId: `${process.env.RAZZLE_FACEBOOK_ID}`,
          hashtags: ["mZaednica"],
        })
        .then((_) => console.log("Yay, you shared it :)"))
        .catch((error) =>
          console.log("Oh noh! You couldn't share it! :'(\n", error)
        );
    }
  };

  const { formatMessage } = useIntl();
  return (
    <>
      <Header />
      {details && (
        <>
          <Helmet>
            <>
              <meta property="og:title" content={details.title} />
              <meta property="og:description" content={details.description} />
              <meta
                property="og:image"
                content={`${process.env.RAZZLE_API_URL}${findImageWithQuality(
                  details.featuredImage[0],
                  "medium"
                )}`}
              />
            </>
          </Helmet>
          <div className="container mt-5 mb-20">
            {details.featuredImage && (
              <img
                className="object-contain h-64 w-full mb-10"
                src={`${process.env.RAZZLE_API_URL}${findImageWithQuality(
                  details.featuredImage[0],
                  "medium"
                )}`}
                alt=""
              />
            )}
            <div className="text-center uppercase text-lg mb-10">
              {details.name}
            </div>
            <div className="text-left p-5 md:p-2">
              <ReactMarkdown>{details.description}</ReactMarkdown>
            </div>
            <div className="flex flex-row mt-10 items-center gap-4">
              {details.hasActiveEnrolment && (
                <Link
                  to={
                    details.type === "school"
                      ? `/confirmEnrolment/${id}`
                      : `/createEnrolmentRequest/2/new`
                  }
                >
                  <button
                    type="submit"
                    className="bg-blue text-white font-bold py-2 px-7 border-4 border-blue rounded-lg focus:outline-none"
                  >
                    {formatMessage({ id: "enrolment" })}
                  </button>
                </Link>
              )}
              <div className="">
                <img src={ShareGray} className="w-7 inline" />
                <button
                  type="button"
                  className="text-md font-semibold text-darkGray1 focus:outline-none"
                  onClick={share}
                >
                  {formatMessage({ id: "share" })}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  educationalFacilityDetails: getEducationalFacilityDetails(state),
});

const mapDispatchToProps = {
  fetchEducationalFacility,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnrolmentDetails);
