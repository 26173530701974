import reducerWithActionMap from "../utils/reducerWithActionsMap";
import * as LanguageActions from "../actions/language";
import { LANGUAGES } from "../../i18n/settings/languages";

const initialState = {
  languageList: [
    { id: 0, label: LANGUAGES.MK },
    { id: 1, label: LANGUAGES.SQ },
  ],
  language: LANGUAGES.MK,
};

export const setLanguage = (state, { payload: language }) => ({
  ...state,
  language,
});

const actionMap = {
  [LanguageActions.SET_LANGUAGE]: setLanguage,
};

export default reducerWithActionMap(actionMap, initialState);
