import getAxiosInstance from '../config/http';

export const LIST_NOTIFICATION_REQUEST = 'NOTIFICATION@LIST_NOTIFICATION_REQUEST';
export const LIST_NOTIFICATION_SUCCESS = 'NOTIFICATION@LIST_NOTIFICATION_SUCCESS';
export const LIST_NOTIFICATION_FAILURE = 'NOTIFICATION@LIST_NOTIFICATION_FAILURE';

export const listNotifications = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_NOTIFICATION_REQUEST,
  });
  try{
    const notificationData = await http.get('/notifications', {params});
    dispatch({
      type: LIST_NOTIFICATION_SUCCESS,
      payload: {
        listData: notificationData.data,
      },
    });
    return notificationData.data;
  }catch(e){
    dispatch({
      type: LIST_NOTIFICATION_FAILURE,
    });
  }
};
