import { useIntl } from "react-intl";

import React, { useCallback, useEffect } from "react";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Field, Form, Formik, ErrorMessage } from "formik";
import Header from "../../components/Header";
import Footer from "../../components/Footer.js";
import CommentMediaField from "../../components/Inputs/CommentMediaField";
import ProblemPrimary from "../../img/problem_primary.svg";
import SuggestionPrimary from "../../img/suggestion_primary.svg";
import SupportIcon from "../../img/support_gray.svg";
import ShareGray from "../../img/share_gray.svg";
import LocationPrimary from "../../img/location_primary.svg";
import CameraGray from "../../img/camera_gray.svg";
import ProfileIcon from "../../img/profile_menu_gray.svg";
import ProcessWhite from "../../img/process_white.svg";
import FinishedWhite from "../../img/finished_white.svg";
import * as Yup from "yup";

import { GoogleMap, Marker } from "@react-google-maps/api";
import SwiperCore, { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

import { fetchIssue } from "../../middleware/actions/issue";
import { openModal } from "../../middleware/actions/modal";
import { getIssueDetails } from "../../middleware/selectors/issue";
import { getUserDetails } from "../../middleware/selectors/user";
import { LOGIN_MODAL_ID } from "../../consts/modalIds";
import { listComments, createComment } from "../../middleware/actions/comment";
import { getComments } from "../../middleware/selectors/comment";
import findImageWithQuality from "../../utils/findImageWithQuality";
import { getLanguage } from "../../middleware/selectors/language";

SwiperCore.use([Navigation, A11y]);

const containerStyle = {
  width: "700px",
  height: "300px",
};

const initialValues = {
  content: "",
  file: null,
};

const validationSchema = Yup.object({
  content: Yup.string().required("Comment is required"),
});

const ReportDetails = ({
  fetchIssue,
  openModal,
  listComments,
  createComment,
  issueDetails,
  userDetails,
  comments,
  language,
}) => {
  let { id } = useParams();
  const history = useHistory();
  let details = issueDetails.issueData;
  let reportIcon = null;
  if (details) {
    if (details.reportType === "problem") {
      reportIcon = ProblemPrimary;
    } else {
      reportIcon = SuggestionPrimary;
    }
  }

  let center = {};

  if (details && details.location) {
    center = {
      lat: parseFloat(details.location.latLng.latitude),
      lng: parseFloat(details.location.latLng.longitude),
    };
  }

  useEffect(() => {
    if (!details || details.id != id) {
      fetchIssue(id);
    }
    listComments({ "report.id": id });
  }, []);

  const submitComment = useCallback(
    async (values, { resetForm }) => {
      if (!userDetails.userData) {
        openModal(LOGIN_MODAL_ID);
      } else if (!userDetails.userData.profileComplete) {
        history.push("completeProfile");
      } else {
        await createComment(values.file, {
          content: values.content,
          report: id,
        });
        resetForm();
      }
    },
    [userDetails]
  );

  const share = (e) => {
    console.log("share");
    console.log(id);
    e.preventDefault();
    if (navigator.share) {
      navigator
        .share({
          url: `${process.env.RAZZLE_WEB_URL}/reportDetails/${id}`,
          // extra, optional options
          fbId: `${process.env.RAZZLE_FACEBOOK_ID}`,
          hashtags: ["mZaednica"],
        })
        .then((_) => console.log("Yay, you shared it :)"))
        .catch((error) =>
          console.log("Oh noh! You couldn't share it! :'(\n", error)
        );
    }
  };

  const { formatMessage } = useIntl();
  return (
    <>
      <Header />
      {/* Reports/Slider */}
      {!issueDetails.isFetching && details && (
        <>
          <Helmet>
            <>
              <meta property="og:title" content={details.title} />
              <meta property="og:description" content={details.description} />
              <meta
                property="og:image"
                content={`${process.env.RAZZLE_API_URL}${findImageWithQuality(
                  details.files[0],
                  "medium"
                )}`}
              />
            </>
          </Helmet>
          <section className="container w-full max-w-5xl pt-7 pb-7">
            <Swiper spaceBetween={0} slidesPerView={1} navigation>
              {details.files.map((file) => {
                return (
                  <SwiperSlide className="px-5">
                    <img
                      src={`${process.env.RAZZLE_API_URL}${findImageWithQuality(
                        file,
                        "medium"
                      )}`}
                      className="h-96 w-full object-contain"
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="flex items-start mt-8 px-5">
              <img
                src={reportIcon}
                alt="Problem icon"
                width="52"
                height="auto"
              />
              <header className="ml-3.5">
                <h1 className="text-footer font-semibold text-xl">
                  {details.title
                    ? language == "mk"
                      ? details.title
                      : details.title_sq ?? details.title
                    : ""}
                </h1>
                <div>
                  {formatMessage({ id: "municipality" })}{" "}
                  {details.municipality.name}
                </div>
                <h6 className="text-darkGray1 mt-1">
                  {moment(details.created_at).format("DD.MM.YYYY")}
                </h6>
              </header>
            </div>
            <div className="px-5 mt-7 text-darkGray1 ">
              {details.category
                ? language == "mk"
                  ? details.category.name
                  : details.category.name_sq ?? details.category.name
                : ""}
            </div>
            <div className="px-5">
              <p className="font-semibold text-darkGray1">
                {details.description
                  ? language == "mk"
                    ? details.description
                    : details.description_sq ?? details.description
                  : ""}
              </p>
            </div>
            <section className="mt-10 grid grid-cols-2 justify-items-center">
              <span className="flex items-center justify-center w-auto">
                <button className="focus:outline-none">
                  <img src={SupportIcon} className="mx-2.5 w-10" />
                </button>
                <div
                  type="button"
                  className="font-extrabold text-blue focus:outline-none ml-1"
                >
                  {details.supportCount}
                </div>
              </span>
              <span
                className="flex items-center w-auto cursor-pointer"
                onClick={share}
              >
                <img src={ShareGray} className="w-7" />
                <button
                  type="button"
                  className="text-md font-semibold text-darkGray1 focus:outline-none"
                >
                  {formatMessage({ id: "share" })}
                </button>
              </span>
            </section>
            <>
              <section className="flex items-center mt-10 mb-6 px-5 md:px-0">
                <img
                  src={LocationPrimary}
                  alt="Problem icon"
                  width="40"
                  height="auto"
                />
                <h5 className="text-darkGray1 ml-3.5">
                  {details.location != null ? details.location.name : ""}
                </h5>
              </section>
              {details.location && (
                <div className="">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={10}
                    // onClick={mapClick}
                    // onLoad={onLoad}
                    // onUnmount={onUnmount}
                  >
                    {/* Child components, such as markers, info windows, etc. */}
                    <>
                      {details.location && <Marker position={center}></Marker>}
                    </>
                  </GoogleMap>
                </div>
              )}
            </>
            <section className="px-5 md:px-0 pt-7">
              {/* Step 1 */}
              <div className="flex h-full">
                <div className="flex flex-col">
                  <div className="w-94 h-auto inline-flex items-center justify-center">
                    <figure className="bg-avatarIcon rounded-full w-16 h-16 flex justify-center items-center flex-shrink-0">
                      <img
                        src={
                          details.reporter && details.reporter.profilePicture
                            ? `${
                                process.env.RAZZLE_API_URL
                              }${findImageWithQuality(
                                details.reporter.profilePicture
                              )}`
                            : ProfileIcon
                        }
                        alt="Problem icon"
                        className="object-cover w-16 h-16 p-1 rounded-full"
                      />
                    </figure>
                  </div>
                  {comments.isFetched && !!comments.listData.length && (
                    <div className="w-94 flex h-full min-h-40 justify-center">
                      <div className="bg-blue w-2.5"></div>
                    </div>
                  )}
                </div>
                <div className="ml-5 md:ml-10">
                  <h6 className="text-base font-semibold text-lightGray3">
                    {formatMessage({ id: "submittedBy" })}
                  </h6>
                  <h4 className="font-semibold text-darkGray1 text-lg">
                    {details.reporter &&
                      `${details.reporter.firstName} ${details.reporter.familyName}`}
                  </h4>
                  <h6 className="text-base font-semibold text-lightGray3">
                    {moment(details.created_at).format("DD.MM.YYYY")}
                  </h6>
                </div>
              </div>

              {!comments.isFetching &&
                comments.isFetched &&
                comments.listData.map((comment, idx) => {
                  const isLast = idx === comments.listData.length - 1;
                  let statusText = "";
                  let statusIcon = ProcessWhite;
                  switch (comment.newStatus) {
                    case "in_progress":
                      statusText = formatMessage({ id: "inProcess" });
                      break;
                    case "reported":
                      statusText = formatMessage({ id: "submitted" });
                      break;
                    case "closed":
                      statusText = formatMessage({ id: "decided" });
                      statusIcon = FinishedWhite;
                      break;
                  }
                  return (
                    <div className="flex h-full">
                      <div className="flex flex-col">
                        <div className="w-94 h-auto inline-flex items-center justify-center">
                          {comment.commentType === "comment" && (
                            <figure className="bg-avatarIcon rounded-full w-16 h-16 flex justify-center items-center flex-shrink-0">
                              <img
                                src={
                                  comment.user && comment.user.profilePicture
                                    ? `${
                                        process.env.RAZZLE_API_URL
                                      }${findImageWithQuality(
                                        comment.user.profilePicture
                                      )}`
                                    : ProfileIcon
                                }
                                alt="Problem icon"
                                className="object-cover w-16 h-16 p-1 rounded-full"
                              />
                            </figure>
                          )}
                          {comment.commentType === "status_change" && (
                            <figure className="bg-blue rounded-full w-68 h-68 inline-flex justify-center items-center flex-shrink-0">
                              <img
                                src={statusIcon}
                                alt="Problem icon"
                                width="46"
                                height="auto"
                              />
                            </figure>
                          )}
                        </div>
                        {!isLast && (
                          <div className="w-94 flex h-full min-h-40 justify-center">
                            <div className="bg-blue w-2.5"></div>
                          </div>
                        )}
                      </div>
                      <div className="ml-5 md:ml-10">
                        <h4 className="font-semibold text-darkGray1 text-lg">
                          {comment.user.firstName} {comment.user.familyName}
                        </h4>
                        <h6 className="text-base font-semibold text-lightGray3">
                          {moment(comment.created_at).format("DD.MM.YYYY")}
                        </h6>
                        <p className="text-base font-semibold text-lightGray3 my-3">
                          {comment.commentType === "comment" && comment.content}
                          {comment.commentType === "status_change" &&
                            statusText}
                        </p>
                        {comment.commentType === "comment" &&
                          comment.files.length != 0 && (
                            <>
                              <div className="flex flex-row justify-start items-start mt-5 mb-5 px-4 md:px-0">
                                {comment.files.map((file) => {
                                  return (
                                    <img
                                      src={`${
                                        process.env.RAZZLE_API_URL
                                      }/${findImageWithQuality(file, "large")}`}
                                      alt="Logo"
                                      className="md:w-64 md:h-64 mr-3"
                                    />
                                  );
                                })}
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  );
                })}

              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitComment}>
                {({ isSubmitting }) => (
                  <Form className="flex flex-col mb-10 flex-wrap md:flex-nowrap px-5 mt-7">
                    <Field name="file">
                      {({ field, form: { setFieldValue } }) => (
                        <CommentMediaField
                          field={field}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    </Field>
                    <div className="flex flex-row">
                      <label
                        htmlFor="file_file"
                        className="flex flex-col justify-center"
                      >
                        <img
                          src={CameraGray}
                          alt="Camera icon"
                          width="54"
                          height="auto"
                        />
                      </label>
                      <label className="grid grid-cols-2 bg-lightGray4 rounded-full py-3.5 px-4 w-full md:ml-3 mt-4 md:mt-0">
                        <Field
                          name="content"
                          type="text"
                          className="w-auto text-lg md:text-xl font-semibold bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3"
                          placeholder={formatMessage({ id: "enterComment" })}
                        />
                        <div className="flex w-auto justify-end items-center">
                          <button
                            type="submit"
                            className="pr-3 text-md font-bold text-blue disabled:opacity-20"
                            disabled={isSubmitting}
                          >
                            {formatMessage({ id: "send" })}
                          </button>
                        </div>
                      </label>
                    </div>
                    <ErrorMessage name="content">
                      {msg => <div className="text-red text-sm mt-1">{msg}</div>}
                    </ErrorMessage>
                  </Form>
                )}
              </Formik>
            </section>
          </section>
        </>
      )}
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  issueDetails: getIssueDetails(state),
  userDetails: getUserDetails(state),
  comments: getComments(state),
  language: getLanguage(state),
});

const mapDispatchToProps = {
  fetchIssue,
  listComments,
  openModal,
  createComment,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetails);
