import React, { useCallback, useRef, useState } from "react";

import UploadIcon from "../../img/upload.svg";

const MediaField = ({ field: { name }, setFieldValue, fieldLabel }) => {
  const [fileState, setFileState] = useState({
    file: null,
    imagePreviewUrl: null,
  });
  const fileUpload = useRef();
  const handleImageChange = useCallback((e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (file) {
      setFileState({
        file: file,
        imagePreviewUrl: URL.createObjectURL(file),
      });
      setFieldValue(name, file);
    }
  }, []);

  return (
    <div className="bg-lightGray3 h-48 flex flex-col justify-center items-center mt-8 md:mt-14 px-4 md:px-0">
      <input
        ref={fileUpload}
        type="file"
        id={`file_${name}`}
        onChange={handleImageChange}
        class="file-upload"
      />
      {fileState.imagePreviewUrl && (
        <img
          src={fileState.imagePreviewUrl}
          className=" h-24 w-full object-contain"
        />
      )}
      <label
        for={`file_${name}`}
        className="text-white text-sm md:text-lg md:mt-4"
      >
        <img
          src={UploadIcon}
          alt="Upload icon"
          width="40"
          height="auto"
          className="mx-auto mb-2"
        />
        <p className="">{fieldLabel}</p>
      </label>
    </div>
  );
};

export default MediaField;
