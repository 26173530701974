import getAxiosInstance from '../config/http';

export const CREATE_COMMENT_REQUEST = "COMMENT@CREATE_COMMENT_REQUEST";
export const CREATE_COMMENT_SUCCESS = "COMMENT@CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_FAILURE = "COMMENT@CREATE_COMMENT_FAILURE";

export const createComment = (files, commentData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_COMMENT_REQUEST,
  });
  try {
    const formData = new FormData();
    formData.append("files.files", files);
    formData.append("data", JSON.stringify(commentData));
    const { data } = await http.post("/report-comments", formData);
    dispatch({
      type: CREATE_COMMENT_SUCCESS,
      payload: {
        comment: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: CREATE_COMMENT_FAILURE,
    });
    throw e;
  }
};

export const LIST_COMMENT_REQUEST = 'COMMENT@LIST_COMMENT_REQUEST';
export const LIST_COMMENT_SUCCESS = 'COMMENT@LIST_COMMENT_SUCCESS';
export const LIST_COMMENT_FAILURE = 'COMMENT@LIST_COMMENT_FAILURE';

export const listComments = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_COMMENT_REQUEST,
  });
  try{
    const commentData = await http.get('/report-comments', {params});
    dispatch({
      type: LIST_COMMENT_SUCCESS,
      payload: {
        listData: commentData.data,
      },
    });
  }catch(e){
    dispatch({
      type: LIST_COMMENT_FAILURE,
    });
  }
};

export const LIST_PREDEFINED_COMMENT_REQUEST = 'COMMENT@LIST_PREDEFINED_COMMENT_REQUEST';
export const LIST_PREDEFINED_COMMENT_SUCCESS = 'COMMENT@LIST_PREDEFINED_COMMENT_SUCCESS';
export const LIST_PREDEFINED_COMMENT_FAILURE = 'COMMENT@LIST_PREDEFINED_COMMENT_FAILURE';

export const listPredefinedComments = () => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_PREDEFINED_COMMENT_REQUEST,
  });
  try{
    const predefinedCommentData = await http.get('/predefined-responses',);
    dispatch({
      type: LIST_PREDEFINED_COMMENT_SUCCESS,
      payload: {
        listData: predefinedCommentData.data,
      },
    });
  }catch(e){
    dispatch({
      type: LIST_PREDEFINED_COMMENT_FAILURE,
    });
  }
};


export const FETCH_COMMENT_REQUEST = 'COMMENT@FETCH_COMMENT_REQUEST';
export const FETCH_COMMENT_SUCCESS = 'COMMENT@FETCH_COMMNENT_SUCCESS';
export const FETCH_COMMENT_FAILURE = 'COMMENT@FETCH_COMMENT_FAILURE';

export const fetchComment = commentId => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_COMMENT_REQUEST,
  });
  try{
    const commentData = await http.get(`/report-comments/${commentId}`);
    dispatch({
      type: FETCH_COMMENT_SUCCESS,
      payload: {
        commentData: commentData.data,
      }
    });
  } catch(e){
    dispatch({
      type: FETCH_COMMENT_FAILURE,
    });
  };
};

export const UPDATE_COMMENT_REQUEST = 'COMMENT@UPDATE_COMMENT_REQUEST';
export const UPDATE_COMMENT_SUCCESS = 'COMMENT@UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_FAILURE = 'COMMENT@UPDATE_COMMENT_FAILURE';

export const updateComment = (id, commentData) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_COMMENT_REQUEST,
  });
  try{
    const data = await http.put(`/report-comments/${id}`, commentData);
    dispatch({
      type: UPDATE_COMMENT_SUCCESS,
      payload: {
        commentData: data,
      }
    });
  } catch(e){
    dispatch({
      type: UPDATE_COMMENT_FAILURE,
    });
  };
};

export const DELETE_COMMENT_REQUEST = 'COMMENT@DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'COMMENT@DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAILURE = 'COMMENT@DELETE_COMMENT_FAILURE';

export const deleteComment = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_COMMENT_REQUEST,
  });
  try{
    const data = await http.delete(`/report-comments/${id}`);
    dispatch({
      type: DELETE_COMMENT_SUCCESS,
      payload: {
        commentId:id,
      }
    });
    return data;
  } catch(e){
    dispatch({
      type: DELETE_COMMENT_FAILURE,
    });
  };
};
