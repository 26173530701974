import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Footer from "../../components/Footer.js";
import NewsCard from "../../components/News/NewsCard";

import { listPosts } from "../../middleware/actions/post";
import { getPosts } from "../../middleware/selectors/post";
import Header from "../../components/Header.js";
import { getLanguage } from "../../middleware/selectors/language.js";
// import withAuthentication from "../../utils/withAuthentication.js";

const News = ({ listPosts, postList, language }) => {
  const { isFetching, isFetched, listData, total } = postList;

  const {
    listData: postListData,
    isFetching: postListFetching,
    isFetched: postListFetched,
  } = postList;

  useEffect(() => {
    listPosts({ _sort: "updated_at:DESC" });
  }, []);

  return (
    <>
      <Header />
      <section className="pt-8 mb-20">
        <div className="container max-w-max gap-30 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {postList.listData.map((item) => {
            return <NewsCard news={item} language={language}></NewsCard>;
          })}
        </div>
      </section>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  postList: getPosts(state),
  language: getLanguage(state),
});

const mapDispatchToProps = {
  listPosts,
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
