import getAxiosInstance from "../config/http";

export const LIST_ENROLMENT_REQUEST = "ENROLMENT@LIST_ENROLMENT_REQUEST";
export const LIST_ENROLMENT_SUCCESS = "ENROLMENT@LIST_ENROLMENT_SUCCESS";
export const LIST_ENROLMENT_FAILURE = "ENROLMENT@LIST_ENROLMENT_FAILURE";

export const listEnrolments = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_ENROLMENT_REQUEST,
  });
  try {
    const enrolmentData = await http.get("/enrolments", { params });
    dispatch({
      type: LIST_ENROLMENT_SUCCESS,
      payload: {
        listData: enrolmentData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: LIST_ENROLMENT_FAILURE,
    });
  }
};

export const COUNT_ENROLMENT_REQUEST = "ENROLMENT@COUNT_ENROLMENT_REQUEST";
export const COUNT_ENROLMENT_SUCCESS = "ENROLMENT@COUNT_ENROLMENT_SUCCESS";
export const COUNT_ENROLMENT_FAILURE = "ENROLMENT@COUNT_ENROLMENT_FAILURE";

export const countEnrolments = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: COUNT_ENROLMENT_REQUEST,
  });
  try {
    const count = await http.get("/enrolments/count", { params });
    dispatch({
      type: COUNT_ENROLMENT_SUCCESS,
      payload: {
        listData: count.data,
      },
    });
    return count.data;
  } catch (e) {
    dispatch({
      type: COUNT_ENROLMENT_FAILURE,
    });
  }
};

export const CREATE_ENROLMENT_REQUEST = "ENROLMENT@CREATE_ENROLMENT_REQUEST";
export const CREATE_ENROLMENT_SUCCESS = "ENROLMENT@CREATE_ENROLMENT_SUCCESS";
export const CREATE_ENROLMENT_FAILURE = "ENROLMENT@CREATE_ENROLMENT_FAILURE";

export const createEnrolment = (file, enrolmentData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_ENROLMENT_REQUEST,
  });
  try {
    const formData = new FormData();
    formData.append("files.attachment", file);
    formData.append("data", JSON.stringify(enrolmentData));
    const { data } = await http.post("/enrolments", formData);
    dispatch({
      type: CREATE_ENROLMENT_SUCCESS,
      payload: {
        enrolmentData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: CREATE_ENROLMENT_FAILURE,
    });
    throw e;
  }
};

export const FETCH_ENROLMENT_REQUEST = "ENROLMENT@FETCH_ENROLMENT_REQUEST";
export const FETCH_ENROLMENT_SUCCESS = "ENROLMENT@FETCH_ENROLMENT_SUCCESS";
export const FETCH_ENROLMENT_FAILURE = "ENROLMENT@FETCH_ENROLMENT_FAILURE";

export const fetchEnrolment = (enrolmentId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_ENROLMENT_REQUEST,
  });
  try {
    const enrolmentData = await http.get(`/enrolments/${enrolmentId}`);

    dispatch({
      type: FETCH_ENROLMENT_SUCCESS,
      payload: {
        enrolmentData: enrolmentData.data,
      },
    });
    return enrolmentData.data;
  } catch (e) {
    dispatch({
      type: FETCH_ENROLMENT_FAILURE,
    });
  }
};
