import enrolments from "./enrolments.json";
import header from "./header.json";
import signInRegister from "./signInRegister.json";
import profile from './profile.json';
import subventionsForm from './subventionsForm.json';
import reportsForm from './reportsForm.json';
import reports from './reports.json';
import general from './general.json';
import adminAdminUsers from './admin-adminUsers.json';
import adminIssues from './admin-issues.json';
import adminSubventions from './admin-subventions.json';
import adminPostsAndStatistics from './admin-postsAndStatistics.json';
import adminHeaderDashLoginOther from './admin-headerDashLoginOther.json';
import news from './news.json';
import adminGeneral from './admin-General.json';
import enrollmentChat from './enrollmentChat.json';
import webAndMobileMissing from './webAndMobileMissing.json';
import homepage from './homepage.json';

export const mk = {
  ...enrolments,
  ...header,
  ...signInRegister,
  ...profile,
  ...subventionsForm,
  ...reportsForm,
  ...reports,
  ...general,
  ...adminPostsAndStatistics,
  ...adminAdminUsers,
  ...adminIssues,
  ...adminHeaderDashLoginOther,
  ...adminSubventions,
  ...news,
  ...adminGeneral,
  ...enrollmentChat,
  ...webAndMobileMissing,
  ...homepage
};
