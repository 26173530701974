import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getProfile } from "./middleware/actions/user";
import { listMunicipalities } from "./middleware/actions/municipality";
import { listInterests } from "./middleware/actions/interest";
import LoginModal  from "./components/Modals/LoginModal";
import EmailLoginModal from "./components/Modals/EmailLoginModal";
import ForgotPasswordModal from "./components/Modals/ForgotPasswordModal";
import SignupModal from "./components/Modals/SignupModal";
import { FacebookProvider } from "react-facebook";
import { listCategories } from "./middleware/actions/category";

const Layout = ({ getProfile, listMunicipalities, listInterests, listCategories, children }) => {
  const history = useHistory();
  useEffect(async () => {
    let response = null;
    try {
      response = await getProfile();
      if (response && !response.profileComplete) {
        history.push("/completeProfile");
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  useEffect(() => {
    listMunicipalities();
    listInterests();
    listCategories();
  });

  return (
    <>
      <FacebookProvider appId={process.env.RAZZLE_FACEBOOK_ID}>
        {children}
        <LoginModal />
        <EmailLoginModal></EmailLoginModal>
        <ForgotPasswordModal></ForgotPasswordModal>
        <SignupModal />
      </FacebookProvider>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  getProfile,
  listMunicipalities,
  listInterests,
  listCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
