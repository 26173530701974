import React,{useState, useEffect, useCallback} from 'react';
import Footer from "../components/Footer";
import ReactMarkdown from 'react-markdown';
import aboutTxt from "../files/About.md";
import Header from '../components/Header';

const About = () => {
  const [markdown, setMarkdown] = useState("");

  const fetchMarkdown = useCallback(async () => {
    const res = await fetch(aboutTxt);
    const text = await res.text();
    setMarkdown(text);
  }, []);

  useEffect(() => {
    fetchMarkdown();
  }, []);

  return(
    <>
      <Header />
      <ReactMarkdown className="container mx-auto pt-5 pb-10 md">{markdown}</ReactMarkdown>
      <Footer />
    </>
  );
};
export default About;
