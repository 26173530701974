import _remove from 'lodash/remove';
import * as SubventionRequestActions from '../actions/subventionRequest';
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  subventionRequestsList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  subventionRequestDetails: {
    isFetching: false,
    isFetched: false,
    subventionRequestData: null,
  },
};

const setSubventionRequestListFetching = fetching => state => ({
  ...state,
  subventionRequestsList: {
    ...state.subventionRequestsList,
    isFetching: fetching,
  },
});

const setSubventionRequestList = (state, { payload: { listData, total } }) => ({
  ...state,
  subventionRequestsList: {
    ...state.subventionRequestsList,
    listData,
    isFetching: false,
    isFetched: true,
  },
});

const setTotalSubventionRequests = (state, { payload: { total } }) => ({
  ...state,
  subventionRequestsList: {
    ...state.subventionRequestsList,
    total
  }
});

const setSubventionRequestFetching = fetching => state => ({
  ...state,
  subventionRequestDetails: {
    ...state.subventionRequestDetails,
    isFetching: fetching,
  },
});

const setSubventionRequest = (state, { payload: { subventionRequestData } }) => ({
  ...state,
  subventionRequestDetails: {
    ...state.subventionRequestDetails,
    subventionRequestData,
    isFetching: false,
    isFetched: true,
  },
});

const setSubventionRequestDeleted = (state, { payload: { subventionRequestId } }) => {
  const newListData = state.subventionRequestsList.listData;
  _remove(newListData, { id: subventionRequestId });

  const newState = {
    ...state,
    subventionRequestsList: {
      ...state.subventionRequestsList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [SubventionRequestActions.LIST_SUBVENTION_CLAIM_REQUEST]: setSubventionRequestListFetching(true),
  [SubventionRequestActions.LIST_SUBVENTION_CLAIM_SUCCESS]: setSubventionRequestList,
  [SubventionRequestActions.LIST_SUBVENTION_CLAIM_FAILURE]: setSubventionRequestListFetching(false),
  [SubventionRequestActions.DELETE_SUBVENTION_CLAIM_SUCCESS]: setSubventionRequestDeleted,
  [SubventionRequestActions.FETCH_SUBVENTION_CLAIM_REQUEST]: setSubventionRequestFetching(true),
  [SubventionRequestActions.FETCH_SUBVENTION_CLAIM_SUCCESS]: setSubventionRequest,
  [SubventionRequestActions.FETCH_SUBVENTION_CLAIM_FAILURE]: setSubventionRequestFetching(false),
  [SubventionRequestActions.COUNT_SUBVENTION_CLAIM_SUCCESS]: setTotalSubventionRequests,
};

export default reducerWithActionMap(actionMap, initialState);


