import _remove from 'lodash/remove';
import * as InterestActions from '../actions/interest';
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  interestList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  interestDetails: {
    isFetching: false,
    isFetched: false,
    interestData: null,
  },
};

const setInterestListFetching = fetching => state => ({
  ...state,
  interestList: {
    ...state.interestList,
    isFetching: fetching,
  },
});

const setInterestList = (state, { payload: { listData, total } }) => ({
  ...state,
  interestList: {
    ...state.interestList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const actionMap = {
  [InterestActions.LIST_INTEREST_REQUEST]: setInterestListFetching(true),
  [InterestActions.LIST_INTEREST_SUCCESS]: setInterestList,
  [InterestActions.LIST_INTEREST_FAILURE]: setInterestListFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);


