import React, { useCallback, useEffect, useRef, useState } from "react";

const initialState = [];

const MultipleMediaField = ({
  field: { name, value },
  setFieldValue,
  fieldLabel,
  ...props
}) => {
  const [fileState, setFileState] = useState(initialState);
  const fileUpload = useRef();

  const handleImageChange = useCallback(
    (e) => {
      e.preventDefault();
      let newValue;
      if (value) {
        newValue = [...value, ...e.target.files];
      } else {
        newValue = [...e.target.files];
      }
      setFieldValue(name, newValue);
    },
    [fileState, name]
  );

  const removeImage = useCallback(
    (idx, e) => {
      e.preventDefault();
      const newValue = [...value];
      newValue.splice(idx, 1);
      setFieldValue(name, newValue);
    },
    [fileState, name]
  );

  useEffect(() => {
    const newStates = [];
    if (value && value.length) {
      for (let i = 0; i < value.length; i++) {
        newStates.push({
          file: value[i],
          imagePreviewUrl: URL.createObjectURL(value[i]),
        });
      }
    }
    setFileState(newStates);
  }, [value]);

  return (
    <div
      className={
        fileState.length != 0
          ? "h-32 flex flex-row justify-start items-start mt-8 md:mt-14 px-4 md:px-0"
          : "hidden"
      }
    >
      <input
        ref={fileUpload}
        type="file"
        multiple
        onChange={handleImageChange}
        className="file-upload"
        {...props}
      />
      {fileState.map((item, idx) => (
        <div key={item.imagePreviewUrl} className="w-32 h-32 relative">
          <img
            src={item.imagePreviewUrl}
            className="max-h-full object-contain"
          />
          <button
            type="button"
            className="absolute top-2 right-2 w-5 h-5 rounded-full text-white bg-black text-center font-bold"
            onClick={removeImage.bind(null, idx)}
          >
            X
          </button>
        </div>
      ))}
    </div>
  );
};;

export default MultipleMediaField;
