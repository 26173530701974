import { useIntl } from "react-intl";


import { Link } from "react-router-dom";

import ProcessOrange from "../../img/process_orange.svg";
import FinishedOrange from "../../img/finished_orange.svg";
import avatar from "../../img/nophoto.jpg";
import findImageWithQuality from "../../utils/findImageWithQuality";

const EducationalFacilityCard = ({ educationalFacility }) => {
  const { id, name, featuredImage, hasActiveEnrolment, municipality } =
    educationalFacility;
    const {formatMessage} = useIntl();

  return (
    <>
      <Link
        to={hasActiveEnrolment ? `/enrolmentDetails/${id}` : null}
        className="block bg-white w-full px-4 py-18 rounded-xl shadow-card"
      >
        <div className="flex">
          <div className="mr-3.5 flex-shrink-0">
            <img
              src={`${process.env.RAZZLE_API_URL}${findImageWithQuality(
                municipality.logo
              )}`}
              alt="Logo"
              className="border-4 border-orange rounded-full w-10 h-10 md:w-16 md:h-16"
            />
          </div>
          <div className="flex flex-col justify-center">
            <h5 className="text-lg font-semibold">
            {formatMessage({id: "municipality"})} {municipality.name}
            </h5>
            <p className="text-darkGray1 text-base align-middle">
              <span
                className={`${
                  !hasActiveEnrolment ? "text-green1" : "text-red"
                } text-green font-bold`}
              >
                {!hasActiveEnrolment ? formatMessage({id: "noActiveEnrollment"}) : formatMessage({id: "activeEnrollment"})}
              </span>
            </p>
            <h4 className="text-sm md:text-base font-bold uppercase my-2">
              {name}
            </h4>
          </div>
        </div>
        <img
          className="h-52 w-full object-cover"
          src={
            featuredImage.length === 0
              ? avatar
              : `${process.env.RAZZLE_API_URL}${findImageWithQuality(
                  featuredImage[0],
                  "small"
                )}`
          }
        />
      </Link>
    </>
  );
};
export default EducationalFacilityCard;
