import { Field } from "formik";
import { flagEnabled } from "tailwindcss/lib/featureFlags";

import FieldLabel from "../../atoms/FieldLabel";

const DateField = ({ fieldLabel, fieldName, isRequired }) => (
  <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
    <FieldLabel label={fieldLabel} isRequired={isRequired} />
    <div className="inline-flex items-center justify-end">
      <Field
        type="date"
        name={fieldName}
        className="w-auto bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3 text-lg md:text-xl"
        placeholder="..."
      />
    </div>
  </label>
);

export default DateField;
