import moment from "moment";

import parseTime from "../utils/parseTime";

const btnClasses =
  "w-auto text-base md:text-lg font-semibold rounded-lg py-2 px-4 mb-4 text-white focus:outline-none";

const HasEnrolmentRequestBar = ({
  meetingInfo,
  onConfirm,
  onReschedule,
  onCancel,
}) => {
  const {
    surname,
    name,
    motherName,
    fatherName,
    meetingDate,
    meetingStartTime,
    meetingEndTime,
  } = meetingInfo;
  return (
    <div className="flex flex-col font-semibold bg-lightGray4 rounded-lg mb-10 flex-wrap md:flex-nowrap p-7 mt-7">
      <div className="mb-5">{moment(meetingDate).format("D MMMM YYYY")}</div>
      <div>
        {surname} {name}, {fatherName}, {motherName}
      </div>
      <div>
        Термин
        <span className="pl-2">
          {parseTime(meetingStartTime)}-{parseTime(meetingEndTime)}
        </span>
      </div>
      <div className="pt-7 flex flex-row space-x-3">
        <button
          className={`${btnClasses} bg-green hover:opacity-80`}
          onClick={onConfirm}
        >
          <span>Потврди</span>
        </button>
        <button
          className={`${btnClasses} bg-lightGray2 hover:bg-blue`}
          onClick={onReschedule}
        >
          <span>Презакажи термин</span>
        </button>
        <button
          className={`${btnClasses} bg-red hover:bg-opacity-80`}
          onClick={onCancel}
        >
          <span>Откажи термин</span>
        </button>
      </div>
    </div>
  );
};

export default HasEnrolmentRequestBar;
