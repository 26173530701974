import _remove from "lodash/remove";
import * as UserActions from "../actions/user";
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  usersList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  userDetails: {
    isFetching: true,
    isFetched: false,
    userData: null,
  },
  advisorDetails: {
    isFetching: true,
    isFetched: false,
    advisorData: null,
  },
  messangerDetails: {
    messangerData: {
      isMuted: null
    }
  }
};

const setUserListFetching = (fetching) => (state) => ({
  ...state,
  usersList: {
    ...state.usersList,
    isFetching: fetching,
  },
});

const setUserList = (state, { payload: { listData, total } }) => ({
  ...state,
  usersList: {
    ...state.usersList,
    listData,
    isFetching: false,
    isFetched: true,
  },
});

const setTotalUsers = (state, { payload: { total } }) => ({
  ...state,
  usersList: {
    ...state.usersList,
    total,
  },
});

const setUserFetching = (fetching) => (state) => ({
  ...state,
  userDetails: {
    ...state.userDetails,
    isFetching: fetching,
  },
});

const setUser = (state, { payload: { userData } }) => ({
  ...state,
  userDetails: {
    ...state.userDetails,
    userData,
    isFetching: false,
    isFetched: true,
  },
});

const setAdvisorFetching = (fetching) => (state) => ({
  ...state,
  advisorDetails: {
    ...state.advisorDetails,
    isFetching: fetching,
  },
});

const setAdvisor = (state, { payload: { advisorData } }) => ({
  ...state,
  advisorDetails: {
    ...state.advisorDetails,
    advisorData,
    isFetching: false,
    isFetched: true,
  },
});

const setUserDeleted = (state, { payload: { userId } }) => {
  const newListData = state.usersList.listData;
  _remove(newListData, { id: userId });

  const newState = {
    ...state,
    usersList: {
      ...state.usersList,
      listData: newListData,
    },
  };
  return newState;
};

const setUserLogout = (state) => {
  const newState = {
    ...state,
    userDetails: {
      ...state.userDetails,
      userData: null,
      isFetched: false,
      isFetching: false,
    },
  };
  return newState;
};

const setMessanger = (state, { payload: { messangerData } }) => {
  return({
    ...state,
    messangerDetails: {
      ...state.messangerDetails,
      messangerData,
      isFetching: false,
      isFetched: true,
    }
  })
};

const updateMessanger = (state, { payload: { messangerData } }) => {
  const newState = {
    ...state,
    messangerDetails: {
      ...state.messangerDetails,
      isMuted: messangerData.isMuted
    }
  };
  return newState;
}

const clearMessangerData = (state) => ({
  ...state,
  messangerDetails: {
    messangerData: null
  },
})

const actionMap = {
  [UserActions.LIST_USER_REQUEST]: setUserListFetching(true),
  [UserActions.LIST_USER_SUCCESS]: setUserList,
  [UserActions.LIST_USER_FAILURE]: setUserListFetching(false),
  [UserActions.DELETE_USER_SUCCESS]: setUserDeleted,
  [UserActions.FETCH_USER_REQUEST]: setUserFetching(true),
  [UserActions.FETCH_USER_SUCCESS]: setUser,
  [UserActions.FETCH_USER_FAILURE]: setUserFetching(false),
  [UserActions.PROFILE_REQUEST]: setUserFetching(true),
  [UserActions.PROFILE_SUCCESS]: setUser,
  [UserActions.PROFILE_FAILURE]: setUserFetching(false),
  [UserActions.LOGIN_SUCCESS]: setUser,
  [UserActions.LOGOUT_SUCCESS]: setUserLogout,
  [UserActions.COUNT_USER_SUCCESS]: setTotalUsers,
  [UserActions.FETCH_ADVISOR_REQUEST]: setAdvisorFetching(true),
  [UserActions.FETCH_ADVISOR_SUCCESS]: setAdvisor,
  [UserActions.FETCH_ADVISOR_FAILURE]: setAdvisorFetching(false),
  [UserActions.FETCH_MESSANGER_SUCCESS]: setMessanger,
  [UserActions.UPDATE_MESSANGER_SUCCESS]: updateMessanger,
  [UserActions.CLEAR_MESSANGER_REQUEST]: clearMessangerData,
};

export default reducerWithActionMap(actionMap, initialState);
