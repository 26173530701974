import { Fragment, useState } from "react";
import * as yup from "yup";
import { Formik, ErrorMessage, Form, Field } from "formik";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import WithConnectedModal from "../../utils/withConnectedModal";
import {
  SIGNUP_MODAL_ID,
  FORGOT_PASSWORD_MODAL_ID,
  EMAIL_LOGIN_MODAL_ID,
} from "../../consts/modalIds";
import { getProfile, login } from "../../middleware/actions/user";
import { openModal, closeModal } from "../../middleware/actions/modal";
import { useIntl } from "react-intl";

const LoginSchema = yup.object().shape({
  identifier: yup.string().required("Ова поле е задолжително"),
  password: yup
    .string()
    .min(5, "Внесете најмалку 5 знаци")
    .required("Ова поле е задолжително"),
});

const startValues = {
  identifier: "",
  password: "",
};

const EmailLoginModal = ({
  isOpen = false,
  closeModal,
  openModal,
  login,
  getProfile,
}) => {
  const history = useHistory();
  const [initialValues, setInitialValues] = useState(startValues);
  const { formatMessage } = useIntl();

  const openSignupModal = () => {
    closeModal();
    openModal(SIGNUP_MODAL_ID);
  };

  const openForgotPasswordModal = () => {
    closeModal();
    openModal(FORGOT_PASSWORD_MODAL_ID);
  };

  const loginUser = async (values) => {
    let user = null;
    try {
      user = await login(values);
      closeModal();
      await getProfile();
      if (!user.user.profileComplete) {
        history.push("/completeProfile");
      } else {
        history.push("/reports");
      }
    } catch (e) {
      alert("Неуспешна најава. Обидете се повторно.");
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={isOpen}
        onClose={closeModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-modal bg-bottom bg-no-repeat bg-cover rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="px-5 sm:px-0">
                <button
                  type="button"
                  className="focus:outline-none focus:ring-0 absolute right-0 text-white p-3"
                  onClick={closeModal}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex flex-col items-center justify-center sm:py-24 py-10 space-y-4 px-8 sm:px-0">
                <Formik
                  initialValues={initialValues}
                  validationSchema={LoginSchema}
                  validateOnChange={false}
                  enableReinitialize={true}
                  onSubmit={loginUser}
                >
                  {({ isSubmitting }) => (
                    <Form className="" autoComplete="on">
                      <label className="text-sm text-left">
                        {formatMessage({ id: "email" })}
                      </label>
                      <Field
                        name="identifier"
                        id="identifier"
                        type="text"
                        placeholder={formatMessage({ id: "enterEmail" })}
                        className="p-2 bg-whiteSmoke mb-4 mt-2 rounded-md border-1 border-lightBlue inline-flex w-full focus:outline-none"
                      />
                      <div className="text-red text-center mb-2">
                        <ErrorMessage name="identifier" />
                      </div>
                      <label className="text-sm text-left">
                        {formatMessage({ id: "pass" })}
                      </label>
                      <Field
                        type="password"
                        name="password"
                        placeholder={formatMessage({ id: "enterPass" })}
                        className="py-2 bg-whiteSmoke mb-3 mt-2 rounded-md border-1 border-lightBlue inline-flex w-full focus:outline-none"
                      />
                      <div className="text-red text-center mb-2">
                        <ErrorMessage name="password" />
                      </div>
                      <div className="text-left text-sm">
                        <button
                          className="font-mont focus:outline-none"
                          type="button"
                          onClick={openForgotPasswordModal}
                        >
                          {formatMessage({ id: "forgotPass" })}
                        </button>
                      </div>
                      <div className="mt-6 text-left flex">
                        <div>
                          <button
                            className="bg-blue px-10 py-2 text-white font-bold rounded-md border-2 border-blue text-center focus:outline-none text-sm md:text-base"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {formatMessage({ id: "login" })}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapDispatchToProps = {
  login,
  openModal,
  getProfile,
};

export default WithConnectedModal(
  EMAIL_LOGIN_MODAL_ID,
  connect(null, mapDispatchToProps)(EmailLoginModal)
);
