// reducers/subventionCategory.js
import * as SubventionCategoryActions from '../actions/subventionCategory';
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  subventionCategoryList: {
    isFetching: false,
    isFetched: false,
    listData: [],
  },
  fetchedSubventionCategory: {
    isFetching: false,
    isFetched: false,
    data: null,
  },
};

const setSubventionCategoryListFetching = (fetching) => (state) => ({
  ...state,
  subventionCategoryList: {
    ...state.subventionCategoryList,
    isFetching: fetching,
  },
});

const setSubventionCategoryList = (state, { payload: { listData } }) => ({
  ...state,
  subventionCategoryList: {
    listData,
    isFetching: false,
    isFetched: true,
  },
});

const setFetchedSubventionCategoryFetching = (fetching) => (state) => ({
  ...state,
  fetchedSubventionCategory: {
    ...state.fetchedSubventionCategory,
    isFetching: fetching,
  },
});

const setFetchedSubventionCategory = (state, { payload: { subventionData } }) => ({
  ...state,
  fetchedSubventionCategory: {
    data: subventionData,
    isFetching: false,
    isFetched: true,
  },
});

const actionMap = {
  [SubventionCategoryActions.LIST_SUBVENTION_CATEGORY_REQUEST]: setSubventionCategoryListFetching(true),
  [SubventionCategoryActions.LIST_SUBVENTION_CATEGORY_SUCCESS]: setSubventionCategoryList,
  [SubventionCategoryActions.LIST_SUBVENTION_CATEGORY_FAILURE]: setSubventionCategoryListFetching(false),
  [SubventionCategoryActions.FETCH_SUBVENTION_CATEGORY_REQUEST]: setFetchedSubventionCategoryFetching(true),
  [SubventionCategoryActions.FETCH_SUBVENTION_CATEGORY_SUCCESS]: setFetchedSubventionCategory,
  [SubventionCategoryActions.FETCH_SUBVENTION_CATEGORY_FAILURE]: setFetchedSubventionCategoryFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);
