import _remove from "lodash/remove";
import * as EnrolmentRequestCommentActions from "../actions/enrolmentRequestComment";
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  enrolmentRequestCommentList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  enrolmentRequestCommentDetails: {
    isFetching: false,
    isFetched: false,
    commentData: null,
  },
};

const setEnrolmentRequestCommentListFetching = (fetching) => (state) => ({
  ...state,
  enrolmentRequestCommentList: {
    ...state.enrolmentRequestCommentList,
    isFetching: fetching,
  },
});

const setEnrolmentRequestCommentList = (
  state,
  { payload: { listData, total } }
) => ({
  ...state,
  enrolmentRequestCommentList: {
    ...state.enrolmentRequestCommentList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setEnrolmentRequestCommentFetching = (fetching) => (state) => ({
  ...state,
  enrolmentRequestCommentDetails: {
    ...state.enrolmentRequestCommentDetails,
    isFetching: fetching,
  },
});

const setEnrolmentRequestComment = (state, { payload: { commentData } }) => ({
  ...state,
  enrolmentRequestCommentDetails: {
    ...state.enrolmentRequestCommentDetails,
    commentData,
    isFetching: false,
    isFetched: true,
  },
});

const setEnrolmentRequestCommentDeleted = (
  state,
  { payload: { commentId } }
) => {
  const newListData = state.enrolmentRequestCommentList.listData;
  _remove(newListData, { id: commentId });

  const newState = {
    ...state,
    enrolmentRequestCommentList: {
      ...state.enrolmentRequestCommentList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [EnrolmentRequestCommentActions.LIST_ENROLMENT_COMMENT_REQUEST]:
    setEnrolmentRequestCommentListFetching(true),
  [EnrolmentRequestCommentActions.LIST_ENROLMENT_COMMENT_SUCCESS]:
    setEnrolmentRequestCommentList,
  [EnrolmentRequestCommentActions.LIST_ENROLMENT_COMMENT_FAILURE]:
    setEnrolmentRequestCommentListFetching(false),
  [EnrolmentRequestCommentActions.DELETE_ENROLMENT_COMMENT_SUCCESS]:
    setEnrolmentRequestCommentDeleted,
  [EnrolmentRequestCommentActions.FETCH_ENROLMENT_COMMENT_REQUEST]:
    setEnrolmentRequestCommentFetching(true),
  [EnrolmentRequestCommentActions.FETCH_ENROLMENT_COMMENT_SUCCESS]:
    setEnrolmentRequestComment,
  [EnrolmentRequestCommentActions.FETCH_ENROLMENT_COMMENT_FAILURE]:
    setEnrolmentRequestCommentFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);
