import { useState, useCallback } from "react";
import Calendar from "react-calendar";
import moment from "moment";

import isSameDay from "../../utils/isSameDay";
import parseDate from "../../utils/parseDate";

const createPossibleSlots = (data) => {
  const { startingHour, finishingHour, meetingDuration } = data;
  const slots = [];
  let date = moment().hour(startingHour).minute(0);

  while (moment(date).hour() < finishingHour) {
    moment(date).hour() < finishingHour &&
      slots.push(
        `${moment(date).hour()}:${moment(date)
          .minute()
          .toString()
          .padStart(2, "0")}`
      );
    date = date.add(meetingDuration, "minute");
  }

  return slots;
};

const EnrolmentCalendar = ({
  meeting,
  dates,
  setFreeSlots,
  enrolmentClaimList,
  requestUpdated,
  cancelationFlow,
  oldSchoolEnrolmentRequest,
  educationalFacility,
  currentTab,
  rescheduleMode,
}) => {
  const [value, onChange] = useState(new Date());
  const data =
    educationalFacility && educationalFacility.educationalFacilityData;

  const tileDisabled = useCallback(
    ({ date }) => {
      const disabledDates = Object.entries(dates).reduce(
        (agg, [key, value]) => {
          if (value.length === 5) agg.push(key);
          // disable Staurdays and Sundays
          if (new Date(key).getDay() === 0 || new Date(key).getDay() === 6)
            agg.push(key);
          if (
            educationalFacility.educationalFacilityData &&
            educationalFacility.educationalFacilityData.nonWorkingDays
          ) {
            const nonworkingday =
              educationalFacility.educationalFacilityData.nonWorkingDays.some(
                (disabledDate) =>
                  date.getFullYear() === new Date(disabledDate).getFullYear() &&
                  date.getMonth() === new Date(disabledDate).getMonth() &&
                  date.getDate() === new Date(disabledDate).getDate()
              );

            nonworkingday && agg.push(date);
          }
          return agg;
        },
        [educationalFacility.educationalFacilityData]
      );

      return disabledDates.find((dDate) => isSameDay(new Date(dDate), date));
    },
    [enrolmentClaimList, dates]
  );

  const onClickCalendarTileHandler = useCallback(
    (value) => {
      // enable click on date only if
      // 1. have meeting/oldSchoolRequest and current tab 0 and is in reschedule mode
      // 2. current tab 1 or 2
      const enabledTileClick =
        ((meeting || oldSchoolEnrolmentRequest) &&
          currentTab === 0 &&
          rescheduleMode) ||
        currentTab !== 0;

      if (enabledTileClick) {
        onChange(value);
        const checkedDate = Object.entries(dates).find(
          ([key]) => key === `${parseDate(value)}`
        );
        const possibleSlots = createPossibleSlots(data);
        const freeSlots = [...possibleSlots];

        checkedDate &&
          checkedDate[1].forEach((d) => {
            const date = new Date(d.meetingStartTime);

            freeSlots.splice(
              freeSlots.indexOf(
                `${moment(date).hour()}:${moment(date)
                  .minute()
                  .toString()
                  .padStart(2, "0")}`
              ),
              1
            );
          });

        const shouldSetFreeSlots =
          (!requestUpdated && checkedDate) || (cancelationFlow && checkedDate);

        shouldSetFreeSlots &&
          setFreeSlots(
            freeSlots.map((slot) => ({
              meetingDate: new Date(checkedDate[0]),
              meetingStartTime: moment(checkedDate[0])
                .hour(slot.split(":")[0])
                .minute(slot.split(":")[1]),
            }))
          );
      }
    },
    [dates, meeting, rescheduleMode, currentTab]
  );

  const minDate = () => {
    return (
      educationalFacility.educationalFacilityData &&
      new Date(educationalFacility.educationalFacilityData.enrolmentStartDate)
    );
  };

  const maxDate = () => {
    return (
      educationalFacility.educationalFacilityData &&
      new Date(educationalFacility.educationalFacilityData.enrolmentEndDate)
    );
  };

  return (
    <Calendar
      onChange={onClickCalendarTileHandler}
      value={value}
      minDate={minDate()}
      maxDate={maxDate()}
      tileDisabled={tileDisabled}
      className="react-calendar-active grid grid-cols-1 border border-grey rounded-md py-3.5 px-4 w-full"
    />
  );
};

export default EnrolmentCalendar;
