import { useIntl } from "react-intl";

export const intl = (string) => {
  const { formatMessage } = useIntl();

  return formatMessage({ id: string });
};

export const getLocale = () => {
  const { locale } = useIntl();

  return locale;
};
