import { Field, Form, Formik } from "formik";
import dayjs from "dayjs";
import { useIntl } from "react-intl";

import parseTime from "../utils/parseTime";

const ChooseTimeSlot = ({
  initalValues,
  onCreateEnrolmentHandler,
  freeSlots,
  duration,
}) => {
  const { formatMessage } = useIntl();
  return (
    <div
      style={{ width: "1000px" }}
      className="flex flex-col font-bold bg-lightGray4 rounded-lg mb-10 flex-wrap md:flex-nowrap p-7 mt-7"
    >
      <p className="text-xl">Одберете термин од слободните:</p>
      <Formik initialValues={initalValues} onSubmit={onCreateEnrolmentHandler}>
        {({ isSubmitting }) => (
          <Form className="container max-w-full pt-10 pb-10">
            <div className="flex flex-row flex-start flex-wrap">
              {freeSlots.length > 0 ? (
                freeSlots.map((slot) => {
                  return (
                    <div
                      key={slot.meetingStartTime}
                      className="flex flex-none h-12"
                    >
                      <label>
                        <Field
                          value={JSON.stringify(slot)}
                          name="newSlot"
                          type="radio"
                          className="h-5 w-5 md:ml-3"
                        />
                        <span className="ml-1.5 mr-10 text-lg md:text-xl">
                          {parseTime(slot.meetingStartTime)}-
                          {parseTime(
                            dayjs(slot.meetingStartTime).add(duration, "minute")
                          )}
                        </span>
                      </label>
                    </div>
                  );
                })
              ) : (
                <>{formatMessage({ id: "noFreeSlotsAvailable" })}</>
              )}
            </div>
            <div className="flex flex-end mt-10 md:mt-14 px-4 md:px-0">
              <button
                disabled={isSubmitting}
                type="submit"
                className="flex justify-center mx-2 py-1.5 px-10 border border-transparent shadow-sm text-md md:text-lg font-semibold rounded-xl text-white bg-blue hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue disabled:opacity-20"
              >
                Потврди
              </button>
              <button
                disabled={isSubmitting}
                className="flex justify-center py-1.5 px-10 border border-transparent shadow-sm text-md md:text-lg font-semibold rounded-xl text-white bg-lightGray2 hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue disabled:opacity-20"
              >
                Откажи
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChooseTimeSlot;
