import * as ModalActions from '../actions/modal';
import reducerWithActionMap from '../utils/reducerWithActionsMap';

const initialState = {
  openModal: {},
  modalData: {},
};

const openModal = (state, { payload: { id, data } }) => ({
  ...state,
  openModal: {
    ...state.openModal,
    [id]: true,
  },
  modalData: {
    ...state.modalData,
    [id]: data,
  },
});

const closeModal = (state, { payload: { id } }) => ({
  ...state,
  openModal: {
    ...state.openModal,
    [id]: false,
  },
  modalData: {
    [id]: null,
  },
});

const actionMap = {
  [ModalActions.OPEN_MODAL]: openModal,
  [ModalActions.CLOSE_MODAL]: closeModal,
};

export default reducerWithActionMap(actionMap, initialState);
