import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

import SubventionCard from "../../components/Subventions/SubventionCard";
import ProcessWhite from "../../img/process_white.svg";
import ProcessBlue from "../../img/process_blue.svg";
import FinishedBlue from "../../img/finished_blue.svg";
import FinishedWhite from "../../img/finished_white.svg";
import { listSubventions } from "../../middleware/actions/subvention";
import { getSubventions } from "../../middleware/selectors/subvention";
import withAuthentication from "../../utils/withAuthentication.js";
import { getLanguage } from "../../middleware/selectors/language";
import { useIntl } from "react-intl";

const Subventions = ({ listSubventions, subventionsList, language }) => {
  const { isFetching, isFetched, listData, total } = subventionsList;
  const [currentTab, setCurrentTab] = useState(0);
  const { formatMessage } = useIntl();

  const {
    listData: subventionListData,
    isFetching: subventionListFetching,
    isFetched: subventionListFetched,
  } = subventionsList;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (currentTab === 0) {
      listSubventions({
        _sort: "updated_at:DESC",
        dueDate_gt: moment().toDate(),
      });
    } else {
      listSubventions({
        _sort: "updated_at:DESC",
        dueDate_lt: moment().toDate(),
      });
    }
  }, [currentTab]);
  return (
    <>
      <section className="pt-8 pb-40">
        <div className="container flex items-center justify-center space-x-5 flex-wrap mb-7">
          <button
            className={`w-auto inline-flex items-center justify-center border-2 border-white text-base md:text-lg font-semibold rounded-full py-2 px-7 mb-4 focus:outline-none ${
              currentTab === 0
                ? "text-white bg-blue"
                : "text-lightGray2 bg-lightGray1 focus:outline-none"
            }`}
            onClick={() => setCurrentTab(0)}
          >
            <img
              src={currentTab === 0 ? ProcessWhite : ProcessBlue}
              alt="Progress icon"
              width="28"
              height="auto"
            />
            <span className="ml-4 md:ml-8">
              {formatMessage({ id: "active" })}
            </span>
          </button>
          <button
            className={`w-auto inline-flex items-center justify-center border-2 border-white text-base md:text-lg font-semibold rounded-full py-2 px-7 mb-4 focus:outline-none ${
              currentTab === 1
                ? "text-white bg-blue"
                : "text-lightGray2 bg-lightGray1 focus:outline-none"
            }`}
            onClick={() => setCurrentTab(1)}
          >
            <img
              src={currentTab === 1 ? FinishedWhite : FinishedBlue}
              alt="Progress icon"
              width="28"
              height="auto"
            />
            <span className="ml-4 md:ml-8">
              {formatMessage({ id: "past" })}
            </span>
          </button>
        </div>
        <>
          {subventionsList.listData.length !== 0 ? (
            <div className="container max-w-max gap-30 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-4 lg:px-0">
              {subventionsList.listData.map((item) => {
                return <SubventionCard subvention={item} language={language} />;
              })}
            </div>
          ) : (
            <div className="text-center">
              {formatMessage({ id: "noSubventions" })}
            </div>
          )}
        </>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  subventionsList: getSubventions(state),
  language: getLanguage(state),
});

const mapDispatchToProps = {
  listSubventions,
};

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(Subventions)
);
