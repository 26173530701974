import _remove from 'lodash/remove';
import * as SubventionRequestCommentActions from '../actions/subventionRequestComment';
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  subventionRequestCommentList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  subventionRequestCommentDetails: {
    isFetching: false,
    isFetched: false,
    commentData: null,
  },
};

const setSubventionRequestCommentListFetching = fetching => state => ({
  ...state,
  subventionRequestCommentList: {
    ...state.subventionRequestCommentList,
    isFetching: fetching,
  },
});

const setSubventionRequestCommentList = (state, { payload: { listData, total } }) => ({
  ...state,
  subventionRequestCommentList: {
    ...state.subventionRequestCommentList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setSubventionRequestCommentFetching = fetching => state => ({
  ...state,
  subventionRequestCommentDetails: {
    ...state.subventionRequestCommentDetails,
    isFetching: fetching,
  },
});

const setSubventionRequestComment = (state, { payload: { commentData } }) => ({
  ...state,
  subventionRequestCommentDetails: {
    ...state.subventionRequestCommentDetails,
    commentData,
    isFetching: false,
    isFetched: true,
  },
});

const setSubventionRequestCommentDeleted = (state, { payload: { commentId } }) => {
  const newListData = state.subventionRequestCommentList.listData;
  _remove(newListData, { id: commentId });

  const newState = {
    ...state,
    subventionRequestCommentList: {
      ...state.subventionRequestCommentList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [SubventionRequestCommentActions.LIST_SUBVENTION_COMMENT_REQUEST]: setSubventionRequestCommentListFetching(true),
  [SubventionRequestCommentActions.LIST_SUBVENTION_COMMENT_SUCCESS]: setSubventionRequestCommentList,
  [SubventionRequestCommentActions.LIST_SUBVENTION_COMMENT_FAILURE]: setSubventionRequestCommentListFetching(false),
  [SubventionRequestCommentActions.DELETE_SUBVENTION_COMMENT_SUCCESS]: setSubventionRequestCommentDeleted,
  [SubventionRequestCommentActions.FETCH_SUBVENTION_COMMENT_REQUEST]: setSubventionRequestCommentFetching(true),
  [SubventionRequestCommentActions.FETCH_SUBVENTION_COMMENT_SUCCESS]: setSubventionRequestComment,
  [SubventionRequestCommentActions.FETCH_SUBVENTION_COMMENT_FAILURE]: setSubventionRequestCommentFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);


