import _remove from 'lodash/remove';
import * as SubventionActions from '../actions/subvention';
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  subventionsList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  subventionDetails: {
    isFetching: false,
    isFetched: false,
    subventionData: null,
  },
};

const setSubventionListFetching = fetching => state => ({
  ...state,
  subventionsList: {
    ...state.subventionsList,
    isFetching: fetching,
  },
});

const setSubventionList = (state, { payload: { listData, total } }) => ({
  ...state,
  subventionsList: {
    ...state.subventionsList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
  subventionDetails: {
    isFetching: false,
    isFetched: false,
    subventionData: null,
  },
});

const setSubventionFetching = (fetching) => (state) => ({
  ...state,
  subventionDetails: {
    ...state.subventionDetails,
    isFetching: fetching,
  },
});

const setSubvention = (state, { payload: { subventionData } }) => ({
  ...state,
  subventionDetails: {
    ...state.subventionDetails,
    subventionData,
    isFetching: false,
    isFetched: true,
  },
});

const setSubventionDeleted = (state, { payload: { subventionId } }) => {
  const newListData = state.subventionsList.listData;
  _remove(newListData, { id: subventionId });

  const newState = {
    ...state,
    subventionsList: {
      ...state.subventionsList,
      listData: newListData,
    },
  };
  return newState;
};

const setSubventionUpdated = (state, { payload: { subventionData } }) => {
  const newListData = state.subventionsList.listData;
  if (!subventionData.data.published_at)
    _remove(newListData, { id: subventionData.data.id });

  const newState = {
    ...state,
    subventionsList: {
      ...state.subventionsList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [SubventionActions.LIST_SUBVENTION_REQUEST]: setSubventionListFetching(true),
  [SubventionActions.LIST_SUBVENTION_SUCCESS]: setSubventionList,
  [SubventionActions.LIST_SUBVENTION_FAILURE]: setSubventionListFetching(false),
  [SubventionActions.DELETE_SUBVENTION_SUCCESS]: setSubventionDeleted,
  [SubventionActions.UPDATE_SUBVENTION_SUCCESS]: setSubventionUpdated,
  [SubventionActions.FETCH_SUBVENTION_REQUEST]: setSubventionFetching(true),
  [SubventionActions.FETCH_SUBVENTION_SUCCESS]: setSubvention,
  [SubventionActions.FETCH_SUBVENTION_FAILURE]: setSubventionFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);


