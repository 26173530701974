import { useIntl } from "react-intl";

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import ReactMarkdown from "react-markdown";

import ShareGray from "../../img/share_gray.svg";
import { fetchPost } from "../../middleware/actions/post";
import { getPostDetails } from "../../middleware/selectors/post";
import Header from "../../components/Header.js";
import withAuthentication from "../../utils/withAuthentication.js";
import findImageWithQuality from "../../utils/findImageWithQuality";
import { getLanguage } from "../../middleware/selectors/language";
import { LANGUAGES } from "../../i18n/settings/languages";

const NewsDetails = ({ fetchPost, postDetails, language }) => {
  let { id } = useParams();
  const history = useHistory();

  let details = postDetails.postData;

  useEffect(() => {
    if (!details || details.id != id) {
      fetchPost(id);
    }
  }, []);

  const share = (e) => {
    e.preventDefault();
    if (navigator.share) {
      navigator
        .share({
          url: `${process.env.RAZZLE_WEB_URL}/newsDetails/${id}`,
          // extra, optional options
          fbId: `${process.env.RAZZLE_FACEBOOK_ID}`,
          hashtags: ["mZaednica"],
        })
        .then((_) => console.log("Yay, you shared it :)"))
        .catch((error) =>
          console.log("Oh noh! You couldn't share it! :'(\n", error)
        );
    }
  };

  const { formatMessage } = useIntl();
  return (
    <>
      <Header />
      {details && !postDetails.isFetching && (
        <>
          <Helmet>
            <>
              <meta property="og:title" content={details.title} />
              <meta property="og:description" content={details.content} />
              <meta property="og:type" content="article" />
              <meta
                property="article:section"
                content={
                  details.interest != null
                    ? language == LANGUAGES.MK
                      ? details.interest.name
                      : details.interest.name_sq ?? details.interest.name
                    : ""
                }
              />
              <meta
                property="og:image"
                content={`${process.env.RAZZLE_API_URL}${findImageWithQuality(
                  details.featuredImage,
                  "medium"
                )}`}
              />
            </>
          </Helmet>
          <div className="container mt-5">
            {details.featuredImage && (
              <img
                className="object-contain h-96 w-full"
                src={`${process.env.RAZZLE_API_URL}${findImageWithQuality(
                  details.featuredImage,
                  "medium"
                )}`}
                alt=""
              />
            )}
            <div className="text-center uppercase text-lg mt-10">
              {language == LANGUAGES.MK
                ? details.title
                : details.title_sq ?? details.title}
            </div>
            <div className="p-5 md:px-64 mb-10">
              <div className="mb-2">
                {details.subtitle != null
                  ? language == LANGUAGES.MK
                    ? details.subtitle
                    : details.subtitle_sq ?? details.subtitle
                  : ""}
              </div>
              <div className="font-bold mb-2">
                {details.interest != null
                  ? language == LANGUAGES.MK
                    ? details.interest.name
                    : details.interest.name_sq ?? details.interest.name
                  : ""}
              </div>
              <div className="mb-5 font-bold">
                {moment(details.created_at).format("DD.MM.YYYY")}
              </div>
              <div className="text-left">
                <ReactMarkdown>
                  {language == LANGUAGES.MK
                    ? details.content
                    : details.content_sq ?? details.content}
                </ReactMarkdown>
              </div>
              <div className="mt-10">
                <img src={ShareGray} className="w-7 inline" />
                <button
                  type="button"
                  className="text-md font-semibold text-darkGray1 focus:outline-none"
                  onClick={share}
                >
                  {formatMessage({ id: "share" })}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  postDetails: getPostDetails(state),
  language: getLanguage(state),
});

const mapDispatchToProps = {
  fetchPost,
};

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(NewsDetails)
);
