export const OPEN_MODAL = 'MODAL@OPEN';
export const CLOSE_MODAL = 'MODAL@CLOSE';

export const openModal = (id, data) => async dispatch => {
  dispatch({
    type: OPEN_MODAL,
    payload: { id, data },
  });
};

export const closeModal = id => async dispatch => {
  dispatch({
    type: CLOSE_MODAL,
    payload: { id },
  });
};
