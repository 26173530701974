import { Field } from "formik";
import MaskedInput from "react-text-mask";

import FieldLabel from "../../atoms/FieldLabel";

const embgMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const TextField = ({
  fieldName,
  fieldLabel,
  validation,
  isRequired,
  classes,
}) => {
  return (
    <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
      {fieldLabel && <FieldLabel label={fieldLabel} isRequired={isRequired} />}
      <div className="inline-flex items-center justify-end">
        <Field type="text" name={fieldName}>
          {({ field }) => {
            let inputProps = {
              ...field,
              className: classes,
              placeholder: "...",
            };
            switch (validation) {
              case "email":
                inputProps.type = "email";
                break;
              case "embg":
                inputProps.mask = embgMask;
                break;
            }
            return inputProps.mask ? (
              <MaskedInput {...inputProps} />
            ) : (
              <input {...inputProps} />
            );
          }}
        </Field>
      </div>
    </label>
  );
};

export default TextField;
