import { useIntl } from "react-intl";


import React, {useState} from "react";
import { Formik, Form, Field } from "formik";
import { useHistory, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {resetPassword} from "../middleware/actions/user";
import { useLocation } from "react-router";

const startValues = {
  password: "",
};

const ResetPassword = ({resetPassword}) => {
  const [initialValues, setInitialValues] = useState(startValues);
  const history = useHistory();
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let token = params.get("token");

  const formSubmit = async (values) => {
    let pass = null;
    try {
      pass = await resetPassword({...values, forgotPasswordToken: token});
      history.push("/");
    }
    catch (e) {
      console.log(e);
      // alert("Something went wrong, please try again");
    }
  };

  const {formatMessage} = useIntl();

  return (
    <div className="container mx-auto font-mont">
      <div className="text-lg text-center mt-10">
        {formatMessage({ id: "resetPass" })}
      </div>
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={formSubmit}
      >
        <Form>
          <div className="container mx-auto w-2/4">
            <div className="mt-10">
              <div className="relative">
                <label htmlFor="title" className="text-gray-700">
                  {formatMessage({ id: "pass" })}
                </label>
                <Field
                  name="password"
                  placeholder={formatMessage({ id: "newPass" })}
                  type="password"
                  className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                ></Field>
              </div>
            </div>
            <div className="w-2/4 mt-16 mb-20 block mx-auto m-0 text-center">
              <button
                type="submit"
                className="mt-5 bg-lightBlue px-10 py-2 text-white font-bold rounded-md border-2 border-lightBlue text-center focus:outline-none"
              >
                {formatMessage({ id: "resetPass" })}
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

const mapDispatchToProps = {
  resetPassword
};

export default connect(null, mapDispatchToProps)(ResetPassword);
