import getAxiosInstance from '../config/http';

export const LIST_INTEREST_REQUEST = 'INTEREST@LIST_INTEREST_REQUEST';
export const LIST_INTEREST_SUCCESS = 'INTEREST@LIST_INTEREST_SUCCESS';
export const LIST_INTEREST_FAILURE = 'INTEREST@LIST_INTEREST_FAILURE';

export const listInterests = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_INTEREST_REQUEST,
  });
  try{
    const interestData = await http.get('/interests', {params});
    dispatch({
      type: LIST_INTEREST_SUCCESS,
      payload: {
        listData: interestData.data,
      },
    });
    return interestData.data;
  }catch(e){
    dispatch({
      type: LIST_INTEREST_FAILURE,
    });
  }
};
