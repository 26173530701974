import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

const MyEnrolmentRequestCard = ({ request }) => {
  const { educational_facility, created_at } = request;
  const currentTime = moment(created_at);
  const { name } = educational_facility;
  const { formatMessage } = useIntl();

  return (
    <>
      <Link
        to={`/enrolmentRequestDetails/${request.id}`}
        className="block bg-white w-full px-4 py-18 rounded-xl shadow-card"
      >
        <div className="flex flex-col">
          <div className="flex flex-col justify-center">
            <span className="text-lightGray2 text-base">
              {currentTime.format("DD.MM.YYYY")}
            </span>
            <h4 className="text-sm md:text-base font-semibold uppercase my-2">
              {formatMessage({ id: "enrollmentFirstGrade2022" })}
              {moment().year()}/{moment().year() + 1} {name}
            </h4>
          </div>
        </div>
      </Link>
    </>
  );
};
export default MyEnrolmentRequestCard;
