import { LANGUAGES } from "../settings/languages";
import { en } from "./en";
import { mk } from "./mk";
import { sq } from "./sq";

export default {
  [LANGUAGES.EN]: { ...en },
  [LANGUAGES.SQ]: { ...sq },
  [LANGUAGES.MK]: { ...mk },
};
