import { useIntl } from "react-intl";

import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer.js";
import ReportCard from "../../components/Reports/ReportCard";
import ReportBlue from "../../img/report_blue.svg";
import ReportWhite from "../../img/report_white.svg";
import ProcessWhite from "../../img/process_white.svg";
import ProcessBlue from "../../img/process_blue.svg";
import FinishedWhite from "../../img/finished_white.svg";
import FinishedBlue from "../../img/finished_blue.svg";
import AddBtn from "../../img/add_btn.svg";

import {
  listIssues,
  fetchIssue,
  listSupportedIssues,
  createSupport,
  removeSupport,
  countIssues,
} from "../../middleware/actions/issue";
import { listInterests } from "../../middleware/actions/interest";
import {
  getIssueCounts,
  getIssues,
  getSupportedIssues,
  getSupports,
} from "../../middleware/selectors/issue";
import Header from "../../components/Header.js";
import withAuthentication from "../../utils/withAuthentication.js";
import { getInterests } from "../../middleware/selectors/interest.js";
import { getLanguage } from "../../middleware/selectors/language.js";

const Reports = ({
  listIssues,
  listSupportedIssues,
  issuesList,
  interestList,
  supportedIssues,
  createSupport,
  removeSupport,
  supports,
  countIssues,
  counts,
  language,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [currentButton, setCurrentButton] = useState(0);

  const [issueCategoryFilter, setIssueCategoryFilter] = useState(null);

  useEffect(() => {
    listInterests();
    listSupportedIssues();
    countIssues(
      { reportType: "problem", status: "reported" },
      "problems_reported"
    );
    countIssues(
      { reportType: "problem", status: "in_progress" },
      "problems_in_progress"
    );
    countIssues({ reportType: "problem", status: "closed" }, "problems_closed");
    countIssues(
      { reportType: "suggestion", status: "reported" },
      "suggestions_reported"
    );
    countIssues(
      { reportType: "suggestion", status: "in_progress" },
      "suggestions_in_progress"
    );
    countIssues(
      { reportType: "suggestion", status: "closed" },
      "suggestions_closed"
    );
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let filter = { _sort: "updated_at:DESC" };
    let reportTypeFilter = {};
    if (currentTab === 1) {
      reportTypeFilter.reportType = "problem";
    } else if (currentTab === 2) {
      reportTypeFilter.reportType = "suggestion";
    }
    let reportStatusFilter = {};
    if (currentButton === 0) {
      reportStatusFilter.status = "reported";
    } else if (currentButton === 1) {
      reportStatusFilter.status = "in_progress";
    } else if (currentButton === 2) {
      reportStatusFilter.status = "closed";
    }
    if (issueCategoryFilter) {
      filter.category = issueCategoryFilter;
    }
    listIssues({ ...filter, ...reportTypeFilter, ...reportStatusFilter });
  }, [currentTab, currentButton, issueCategoryFilter]);

  const toggleCategoryFilter = useCallback(
    (category) => {
      if (issueCategoryFilter === category) {
        setIssueCategoryFilter(null);
      } else {
        setIssueCategoryFilter(category);
      }
    },
    [issueCategoryFilter]
  );

  let reported = 0;
  let inProgress = 0;
  let closed = 0;
  if (currentTab === 0 || currentTab == 3) {
    reported = counts.problems_reported + counts.suggestions_reported;
    inProgress = counts.problems_in_progress + counts.suggestions_in_progress;
    closed = counts.problems_closed + counts.suggestions_closed;
  }
  if (currentTab === 1) {
    reported = counts.problems_reported;
    inProgress = counts.problems_in_progress;
    closed = counts.problems_closed;
  } else if (currentTab === 2) {
    reported = counts.suggestions_reported;
    inProgress = counts.suggestions_in_progress;
    closed = counts.suggestions_closed;
  }

  const { formatMessage } = useIntl();
  return (
    <>
      <Header />
      <section className="pb-16 pt-10">
        <div className="flex items-center justify-center space-x-5 flex-wrap mb-4">
          <button
            className={`text-lightGray2 font-semibold text-base md:text-xl uppercase border-b-4 border-transparent hover:border-blue px-4 py-1 mb-3 focus:outline-none ${
              currentTab === 0
                ? "text-blue font-semibold text-base md:text-xl uppercase border-b-4 border-blue px-4 py-1 mb-3 focus:outline-none"
                : "text-lightGray2 focus:outline-none"
            }`}
            onClick={setCurrentTab.bind(null, 0)}
          >
            {formatMessage({ id: "report" })}
          </button>
          <button
            className={`text-lightGray2 font-semibold text-base md:text-xl uppercase border-b-4 border-transparent hover:border-blue px-4 py-1 mb-3 focus:outline-none ${
              currentTab === 1
                ? "text-blue font-semibold text-base md:text-xl uppercase border-b-4 border-blue px-4 py-1 mb-3 focus:outline-none"
                : "text-lightGray2 focus:outline-none"
            }`}
            onClick={setCurrentTab.bind(null, 1)}
          >
            {formatMessage({ id: "problem" })}
          </button>
          <button
            className={`text-lightGray2 font-semibold text-base md:text-xl uppercase border-b-4 border-transparent hover:border-blue px-4 py-1 mb-3 focus:outline-none ${
              currentTab === 2
                ? "text-blue font-semibold text-base md:text-xl uppercase border-b-4 border-blue px-4 py-1 mb-3 focus:outline-none"
                : "text-lightGray2 focus:outline-none"
            }`}
            onClick={setCurrentTab.bind(null, 2)}
          >
            {formatMessage({ id: "suggestion" })}
          </button>
          <button
            className={`text-lightGray2 font-semibold text-base md:text-xl uppercase border-b-4 border-transparent hover:border-blue px-4 py-1 mb-3 focus:outline-none ${
              currentTab === 3
                ? "text-blue font-semibold text-base md:text-xl uppercase border-b-4 border-blue px-4 py-1 mb-3 focus:outline-none"
                : "text-lightGray2 focus:outline-none"
            }`}
            onClick={setCurrentTab.bind(null, 3)}
          >
            {formatMessage({ id: "popular" })}
          </button>
        </div>
        <div className="flex items-center justify-center space-x-5 flex-wrap mb-7">
          <button
            className={`w-auto inline-flex items-center justify-center border-2 border-white text-base md:text-lg font-semibold rounded-full py-2 px-7 mb-4 focus:outline-none ${
              currentButton === 0
                ? "text-white bg-blue"
                : "text-lightGray2 bg-lightGray1 focus:outline-none"
            }`}
            onClick={setCurrentButton.bind(null, 0)}
          >
            <img
              src={currentButton === 0 ? ReportWhite : ReportBlue}
              width="28"
              height="auto"
              className="mr-2"
            />
            {formatMessage({ id: "report" })}{" "}
            <span className="font-black ml-1">{reported}</span>
          </button>
          <button
            className={`w-auto inline-flex items-center justify-center border-2 border-white text-base md:text-lg font-semibold rounded-full py-2 px-7 mb-4 focus:outline-none ${
              currentButton === 1
                ? "text-white bg-blue"
                : "text-lightGray2 bg-lightGray1 focus:outline-none"
            }`}
            onClick={setCurrentButton.bind(null, 1)}
          >
            <img
              src={currentButton === 1 ? ProcessWhite : ProcessBlue}
              width="28"
              height="auto"
              className="mr-2"
            />
            {formatMessage({ id: "inProcess" })}
            <span className="font-black ml-1">{inProgress}</span>
          </button>
          <button
            className={`w-auto inline-flex items-center justify-center border-2 border-white text-base md:text-lg font-semibold rounded-full py-2 px-7 mb-4 focus:outline-none ${
              currentButton === 2
                ? "text-white bg-blue"
                : "text-lightGray2 bg-lightGray1 focus:outline-none"
            }`}
            onClick={setCurrentButton.bind(null, 2)}
          >
            <img
              src={currentButton === 2 ? FinishedWhite : FinishedBlue}
              width="28"
              className="mr-2"
            />
            {formatMessage({ id: "resolved" })}{" "}
            <span className="font-black ml-1">{closed}</span>
          </button>
        </div>
        <div className="flex items-center justify-center space-x-5 flex-wrap">
          {interestList.listData.map((item) => {
            return (
              <button
                key={item.id}
                onClick={() => {
                  toggleCategoryFilter(item.id);
                }}
                className={`w-auto inline-flex items-center justify-center border border-transparent text-sm md:text-base font-semibold rounded-full bg-blue hover:bg-opacity-80 py-1.5 px-3 mb-4 ${
                  issueCategoryFilter === item.id
                    ? " text-white bg-blue "
                    : "text-darkGray bg-lightGray4"
                }`}
              >
                {language == "mk" ? item.name : item.name_sq}
              </button>
            );
          })}
        </div>
        <div className="container gap-30 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {!issuesList.isFetching &&
            issuesList.listData.map((item) => {
              return (
                <ReportCard
                  report={item}
                  supported={supports.supportedMap[item.id]}
                  key={item.id}
                  createSupport={createSupport}
                  removeSupport={removeSupport}
                  language={language}
                ></ReportCard>
              );
            })}
        </div>
        <Link to="/createReport">
          <img
            src={AddBtn}
            className="fixed top-3/4 right-0 w-20 h-20 mr-4 md:mr-6"
          />
        </Link>
      </section>
      <Footer />
    </>
  );
};
const mapStateToProps = (state) => ({
  issuesList: getIssues(state),
  supportedIssues: getSupportedIssues(state),
  interestList: getInterests(state),
  supports: getSupports(state),
  counts: getIssueCounts(state),
  language: getLanguage(state),
});

const mapDispatchToProps = {
  listInterests,
  listIssues,
  listSupportedIssues,
  createSupport,
  removeSupport,
  countIssues,
};

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(Reports)
);
