import _remove from 'lodash/remove';
import * as NotificationActions from '../actions/notification';
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  notificationList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  notificationDetails: {
    isFetching: false,
    isFetched: false,
    notificationData: null,
  },
};

const setNotificationListFetching = fetching => state => ({
  ...state,
  notificationList: {
    ...state.notificationList,
    isFetching: fetching,
  },
});

const setNotificationList = (state, { payload: { listData, total } }) => ({
  ...state,
  notificationList: {
    ...state.notificationList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const actionMap = {
  [NotificationActions.LIST_NOTIFICATION_REQUEST]: setNotificationListFetching(true),
  [NotificationActions.LIST_NOTIFICATION_SUCCESS]: setNotificationList,
  [NotificationActions.LIST_NOTIFICATION_FAILURE]: setNotificationListFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);


