import _remove from "lodash/remove";
import * as EducationalFacilityActions from "../actions/educationalFacility";
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  educationalFacilityList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  educationalFacilityDetails: {
    isFetching: false,
    isFetched: false,
    educationalFacilityData: null,
  },
};

const setEducationalFacilityListFetching = (fetching) => (state) => ({
  ...state,
  educationalFacilityList: {
    ...state.educationalFacilityList,
    isFetching: fetching,
  },
});

const setEducationalFacilityList = (
  state,
  { payload: { listData, total } }
) => ({
  ...state,
  educationalFacilityList: {
    ...state.educationalFacilityList,
    listData,
    isFetching: false,
    isFetched: true,
  },
});

const setTotalEducationalFacilities = (state, { payload: { total } }) => ({
  ...state,
  educationalFacilityList: {
    ...state.educationalFacilityList,
    total,
  },
});

const setEducationalFacilityFetching = (fetching) => (state) => ({
  ...state,
  educationalFacilityDetails: {
    ...state.educationalFacilityDetails,
    isFetching: fetching,
  },
});

const setEducationalFacility = (
  state,
  { payload: { educationalFacilityData } }
) => ({
  ...state,
  educationalFacilityDetails: {
    ...state.educationalFacilityDetails,
    educationalFacilityData,
    isFetching: false,
    isFetched: true,
  },
});

const setEducationalFacilityDeleted = (
  state,
  { payload: { educationalFacilityId } }
) => {
  const newListData = state.educationalFacilityList.listData;
  _remove(newListData, { id: educationalFacilityId });

  const newState = {
    ...state,
    educationalFacilityList: {
      ...state.educationalFacilityList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [EducationalFacilityActions.LIST_EDUCATIONAL_FACILITY_REQUEST]:
    setEducationalFacilityListFetching(true),
  [EducationalFacilityActions.LIST_EDUCATIONAL_FACILITY_SUCCESS]:
    setEducationalFacilityList,
  [EducationalFacilityActions.LIST_EDUCATIONAL_FACILITY_FAILURE]:
    setEducationalFacilityListFetching(false),
  [EducationalFacilityActions.DELETE_EDUCATIONAL_FACILITY_SUCCESS]:
    setEducationalFacilityDeleted,
  [EducationalFacilityActions.FETCH_EDUCATIONAL_FACILITY_REQUEST]:
    setEducationalFacilityFetching(true),
  [EducationalFacilityActions.FETCH_EDUCATIONAL_FACILITY_SUCCESS]:
    setEducationalFacility,
  [EducationalFacilityActions.FETCH_EDUCATIONAL_FACILITY_FAILURE]:
    setEducationalFacilityFetching(false),
  [EducationalFacilityActions.COUNT_EDUCATIONAL_FACILITY_SUCCESS]:
    setTotalEducationalFacilities,
};

export default reducerWithActionMap(actionMap, initialState);
