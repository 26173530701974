import _remove from "lodash/remove";
import * as EnrolmentRequestActions from "../actions/enrolmentRequest";
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  cancelationFlow: false,
  docsSubmitionType: null,
  chosenDate: null,
  enrolmentRequestList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  enrolmentRequestDetails: {
    isFetching: false,
    isFetched: false,
    enrolmentRequestData: null,
  },
  enrolmentMeetingTimes: {
    isFetching: false,
    isFetched: false,
    listData: [],
  },
};

const setEnrolmentRequestListFetching = (fetching) => (state) => ({
  ...state,
  enrolmentsRequestList: {
    ...state.enrolmentRequestList,
    isFetching: fetching,
  },
});

const setEnrolmentRequestList = (state, { payload: { listData } }) => {
  return {
    ...state,
    enrolmentRequestList: {
      ...state.enrolmentRequestList,
      listData,
      isFetching: false,
      isFetched: true,
    },
  };
};

const setTotalEnrolmentRequests = (state, { payload: { total } }) => ({
  ...state,
  enrolmentRequestList: {
    ...state.enrolmentRequestList,
    total,
  },
});

const setEnrolmentRequestFetching = (fetching) => (state) => ({
  ...state,
  enrolmentRequestDetails: {
    ...state.enrolmentRequestDetails,
    isFetching: fetching,
  },
});

const setEnrolmentRequest = (state, { payload: { enrolmentRequestData } }) => ({
  ...state,
  enrolmentRequestDetails: {
    ...state.enrolmentRequestDetails,
    enrolmentRequestData,
    isFetching: false,
    isFetched: true,
  },
});

const setEnrolmentRequestDeleted = (
  state,
  { payload: { enrolmentRequestId } }
) => {
  const newListData = state.enrolmentRequestList.listData;
  _remove(newListData, { id: enrolmentRequestId });

  const newState = {
    ...state,
    enrolmentsList: {
      ...state.enrolmentRequestList,
      listData: newListData,
    },
  };
  return newState;
};

const setCancelationFlow = (state, { payload: { meeting } }) => {
  const newState = {
    ...state,
    cancelationFlow: true,
    meeting,
  };
  return newState;
};
const resetEnrolmentRequest = (state) => {
  const newState = {
    ...state,
    enrolmentRequestDetails: {
      ...state.enrolmentRequestDetails,
      enrolmentRequestData: null,
    },
  };
  return newState;
};

const setEnrolmentDocTypeSubmition = (
  state,
  { payload: { docsSubmitionType } }
) => {
  const newState = {
    ...state,
    docsSubmitionType,
  };

  return newState;
};

const setEnrolmentChosenDate = (state, { payload: { chosenDate } }) => {
  const newState = {
    ...state,
    chosenDate,
  };

  return newState;
};

const setEnrolmentMeetingTimesFetching = (fetching) => (state) => ({
  ...state,
  enrolmentsRequestList: {
    ...state.enrolmentRequestList,
    isFetching: fetching,
  },
});

const setEnrolmentMeetingTimes = (state, { payload: { listData } }) => {
  return {
    ...state,
    enrolmentRequestList: {
      ...state.enrolmentRequestList,
      listData,
      isFetching: false,
      isFetched: true,
    },
  };
};

const actionMap = {
  [EnrolmentRequestActions.LIST_ENROLMENT_CLAIM_REQUEST]:
    setEnrolmentRequestListFetching(true),
  [EnrolmentRequestActions.LIST_ENROLMENT_CLAIM_SUCCESS]:
    setEnrolmentRequestList,
  [EnrolmentRequestActions.LIST_ENROLMENT_CLAIM_FAILURE]:
    setEnrolmentRequestListFetching(false),
  [EnrolmentRequestActions.LIST_ENROLMENT_MEETING_TIMES_REQUEST]:
    setEnrolmentMeetingTimesFetching(true),
  [EnrolmentRequestActions.LIST_ENROLMENT_MEETING_TIMES_SUCCESS]:
    setEnrolmentMeetingTimes,
  [EnrolmentRequestActions.LIST_ENROLMENT_MEETING_TIMES_FAILURE]:
    setEnrolmentMeetingTimesFetching(false),
  [EnrolmentRequestActions.DELETE_ENROLMENT_CLAIM_SUCCESS]:
    setEnrolmentRequestDeleted,
  [EnrolmentRequestActions.FETCH_ENROLMENT_CLAIM_REQUEST]:
    setEnrolmentRequestFetching(true),
  [EnrolmentRequestActions.FETCH_ENROLMENT_CLAIM_SUCCESS]: setEnrolmentRequest,
  [EnrolmentRequestActions.FETCH_ENROLMENT_CLAIM_FAILURE]:
    setEnrolmentRequestFetching(false),
  [EnrolmentRequestActions.COUNT_ENROLMENT_CLAIM_SUCCESS]:
    setTotalEnrolmentRequests,
  [EnrolmentRequestActions.SET_CANCELATION_FLOW]: setCancelationFlow,
  [EnrolmentRequestActions.RESET_ENROLMENT_CLAIM]: resetEnrolmentRequest,
  [EnrolmentRequestActions.SET_ENROLMENT_DOCTYPE_SUBMITION]:
    setEnrolmentDocTypeSubmition,
  [EnrolmentRequestActions.SET_ENROLMENT_CHOSEN_DATE]: setEnrolmentChosenDate,
};

export default reducerWithActionMap(actionMap, initialState);
