import { useIntl } from "react-intl";
import { connect } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import ReactMarkdown from "react-markdown";

import Footer from "../components/Footer";
import privacyPolicyTxt from "../files/PrivacyPolicy.md";
import privacyPolicyTxtSq from "../files/PrivacyPolicy_sq.md";
import Header from "../components/Header";
import izjava1 from "../files/izjava.docx";
import izjava2 from "../files/pravo-povlekuvanje-karposh.docx";
import izjava3 from "../files/pravo-povlekuvanje-valandovo.docx";
import { LANGUAGES } from "../i18n/settings/languages";
import { getLanguage } from "../middleware/selectors/language";

const PrivacyPolicy = ({ language }) => {
  const [markdown, setMarkdown] = useState("");

  const fetchMarkdown = useCallback(async () => {
    const res = await fetch(
      language == LANGUAGES.MK ? privacyPolicyTxt : privacyPolicyTxtSq
    );
    const text = await res.text();
    setMarkdown(text);
  }, []);

  useEffect(() => {
    fetchMarkdown();
  }, []);

  const { formatMessage } = useIntl();
  return (
    <>
      <Header />
      <ReactMarkdown className="container mx-auto pt-5 pb-10 md">
        {markdown}
      </ReactMarkdown>
      <div className="container mt-10 pb-10">
        <a href={izjava1} className="text-blue">
          Изјава за согласност
        </a>
        <br />
        <a href={izjava2} className="text-blue">
          Барање за повлекување на согласност за обработка на лични податоци -
          Општина Карпош
        </a>
        <br />
        <a href={izjava3} className="text-blue">
          Барање за повлекување на согласност за обработка на лични податоци -
          Општина Валандово
        </a>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  language: getLanguage(state),
});

export default connect(mapStateToProps)(PrivacyPolicy);
