import { useIntl } from "react-intl";

import React, { useCallback } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import findImageWithQuality from "../../utils/findImageWithQuality";
import ProblemGray from "../../img/problem_gray.svg";
import SuggestionGray from "../../img/suggestion_gray.svg";
import ReportGray from "../../img/report_gray.svg";
import ProcessGray from "../../img/process_gray.svg";
import ClosedGray from "../../img/finished_gray.svg";
import SupportGray from "../../img/support_gray.svg";
import ShareGray from "../../img/share_gray.svg";
import avatar from "../../img/nophoto.jpg";

const ReportCard = ({ report, supported, createSupport, removeSupport }) => {
  const {
    id,
    title,
    description,
    reportType,
    status,
    reporter,
    files,
    municipality,
    supportCount,
    category,
    created_at,
  } = report;
  let type = "";
  let reportStatus = "";
  let statusImg = "";
  if (reportType === "problem") {
    type = "Проблем";
  } else {
    type = "Предлог";
  }

  if (status === "reported") {
    reportStatus = "Пријавено";
    statusImg = ReportGray;
  } else if (status === "in_progress") {
    reportStatus = "Во процедура";
    statusImg = ProcessGray;
  } else {
    reportStatus = "Затворено";
    statusImg = ClosedGray;
  }

  const supportPressed = useCallback(
    (e) => {
      e.preventDefault();
      if (!supported) {
        createSupport(id);
      } else {
        removeSupport(supported.id);
      }
    },
    [supported]
  );

  const share = (e) => {
    console.log("share");
    console.log(id);
    e.preventDefault();
    if (navigator.share) {
      navigator
        .share({
          url: `${process.env.RAZZLE_WEB_URL}/reportDetails/${id}`,
          // extra, optional options
          fbId: `${process.env.RAZZLE_FACEBOOK_ID}`,
          hashtags: ["mZaednica"],
        })
        .then((_) => console.log("Yay, you shared it :)"))
        .catch((error) =>
          console.log("Oh noh! You couldn't share it! :'(\n", error)
        );
    }
  };

  const { formatMessage } = useIntl();

  return (
    <>
      <Link to={`/reportDetails/${id}`}>
        <div className="bg-white max-w-full mx-auto px-18 py-9 rounded-xl shadow-xl">
          <div className="flex flex-wrap max-w-full">
            <figure className="mr-3.5 mb-3 2xl:mb-0 w-16 h-16">
              <img
                src={reportType === "problem" ? ProblemGray : SuggestionGray}
                className="w-14 h-14 md:w-72 md:h-72"
                alt="Report type icon"
              />
            </figure>
            <div className="mb-5 flex-1 overflow-hidden">
              <h4 className="text-lightGray2 text-base align-middle">
                {type} &#8226; {moment(created_at).format("DD.MM.YYYY")} &#8226;{" "}
                <img
                  src={statusImg}
                  className="w-7 h-7 inline-block align-middle"
                />{" "}
                {reportStatus}
              </h4>
              <div className="">
                <h5 className="text-footer text-lg md:text-xl font-semibold overflow-ellipsis whitespace-nowrap overflow-hidden">
                  {title}
                </h5>
              </div>

              <h6 className="text-footer text-base font-extralight">
                {category ? category.name : <>&nbsp;</>}
              </h6>
            </div>
          </div>
          <img
            className="h-52 w-full object-cover"
            src={
              files.length === 0
                ? avatar
                : `${process.env.RAZZLE_API_URL}${findImageWithQuality(
                    files[0],
                    "small"
                  )}`
            }
          />
          <div className="flex justify-between flex-wrap">
            <span className="text-base font-extrabold text-darkGray1 inline-flex mt-7 items-center">
              {supportCount}
              <img
                src={SupportGray}
                width="36"
                height="36"
                className="w-9 h-auto mx-2.5"
              />
              <button
                type="button"
                className="text-base font-extrabold text-darkGray1 focus:outline-none"
                onClick={supportPressed}
              >
                {supported ? "Откажи поддршка" : "Поддржи"}
              </button>
            </span>
            <span className="text-base font-extrabold text-darkGray1 inline-flex mt-7 items-center">
              <img
                src={ShareGray}
                width="26"
                height="auto"
                className="w-7 h-auto mx-2.5"
              />
              <button
                type="button"
                className="text-base font-extrabold text-darkGray1 focus:outline-none"
                onClick={share}
              >
                {formatMessage({ id: "share" })}
              </button>
            </span>
          </div>
        </div>
      </Link>
    </>
  );
};
export default ReportCard;
