import { useState } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Subventions from "../Subventions/Subventions";
import Enrolments from "../Enrolments/Enrolments";
import { useIntl } from "react-intl";

const MyServices = () => {
  const { hash } = useLocation();
  const [currentTab, setCurrentTab] = useState(
    hash ? hash.slice(1) : "subventions"
  );
  const { formatMessage } = useIntl();

  return (
    <>
      <Header />
      <section className="pb-16 pt-10">
        <div className="flex items-center justify-center space-x-5 flex-wrap mb-4">
          <button
            className={`text-lightGray2 font-semibold text-base md:text-xl uppercase border-b-4 border-transparent hover:border-blue px-4 py-1 mb-3 focus:outline-none ${
              currentTab === "subventions"
                ? "text-blue font-semibold text-base md:text-xl uppercase border-b-4 border-blue px-4 py-1 mb-3 focus:outline-none"
                : "text-lightGray2 focus:outline-none"
            }`}
            onClick={() => setCurrentTab("subventions")}
          >
            {formatMessage({ id: "subventions" }).toUpperCase()}
          </button>
          <button
            className={`text-lightGray2 font-semibold text-base md:text-xl uppercase border-b-4 border-transparent hover:border-blue px-4 py-1 mb-3 focus:outline-none ${
              currentTab === "enrolments"
                ? "text-blue font-semibold text-base md:text-xl uppercase border-b-4 border-blue px-4 py-1 mb-3 focus:outline-none"
                : "text-lightGray2 focus:outline-none"
            }`}
            onClick={() => setCurrentTab("enrolments")}
          >
            {formatMessage({ id: "enrolments" }).toUpperCase()}
          </button>
        </div>

        {currentTab === "subventions" && <Subventions />}
        {currentTab === "enrolments" && <Enrolments />}
      </section>
      <Footer />
    </>
  );
};

export default MyServices;
