import _remove from 'lodash/remove';
import * as CategoryActions from '../actions/category';
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  categoryList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  categoryDetails: {
    isFetching: false,
    isFetched: false,
    categoryData: [],
  },
};

const setCategoryListFetching = fetching => state => ({
  ...state,
  categoryList: {
    ...state.categoryList,
    isFetching: fetching,
  },
});

const setCategoryList = (state, { payload: { listData, total } }) => ({
  ...state,
  categoryList: {
    ...state.categoryList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setCategoryFetching = (fetching) => (state) => ({
  ...state,
  categoryDetails: {
    ...state.categoryDetails,
    isFetching: fetching,
  },
});

const setCategory = (state, { payload: { categoryData } }) => ({
  ...state,
  categoryDetails: {
    ...state.categoryDetails,
    categoryData,
    isFetching: false,
    isFetched: true,
  },
});

const setCategoryDeleted = (state, { payload: { categoryId } }) => {
  const newListData = state.categoryList.listData;
  _remove(newListData, { id: categoryId });

  const newState = {
    ...state,
    categoryList: {
      ...state.categoryList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [CategoryActions.LIST_CATEGORY_REQUEST]: setCategoryListFetching(true),
  [CategoryActions.LIST_CATEGORY_SUCCESS]: setCategoryList,
  [CategoryActions.LIST_CATEGORY_FAILURE]: setCategoryListFetching(false),
  [CategoryActions.LIST_CATEGORY_REQUEST]: setCategoryListFetching(true),
  [CategoryActions.LIST_CATEGORY_SUCCESS]: setCategoryList,
  [CategoryActions.LIST_CATEGORY_FAILURE]: setCategoryListFetching(false),
  [CategoryActions.DELETE_CATEGORY_SUCCESS]: setCategoryDeleted,
  [CategoryActions.FETCH_CATEGORY_REQUEST]: setCategoryFetching(true),
  [CategoryActions.FETCH_CATEGORY_SUCCESS]: setCategory,
  [CategoryActions.FETCH_CATEGORY_FAILURE]: setCategoryFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);


