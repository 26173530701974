import getAxiosInstance from "../config/http";

export const CREATE_ENROLMENT_COMMENT_REQUEST =
  "ENROLMENT_REQUEST_COMMENT@CREATE_ENROLMENT_COMMENT_REQUEST";
export const CREATE_ENROLMENT_COMMENT_SUCCESS =
  "ENROLMENT_REQUEST_COMMENT@CREATE_ENROLMENT_COMMENT_SUCCESS";
export const CREATE_ENROLMENT_COMMENT_FAILURE =
  "ENROLMENT_REQUEST_COMMENT@CREATE_ENROLMENT_COMMENT_FAILURE";

export const createEnrolmentRequestComment =
  (file, commentData) => async (dispatch) => {
    const http = getAxiosInstance();
    dispatch({
      type: CREATE_ENROLMENT_COMMENT_REQUEST,
    });
    try {
      const formData = new FormData();
      formData.append("files.files", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      formData.append("data", JSON.stringify(commentData));
      const { data } = await http.post("/enrolment-request-comments", formData);
      dispatch({
        type: CREATE_ENROLMENT_COMMENT_SUCCESS,
        payload: {
          commentData: data,
        },
      });
      return data;
    } catch (e) {
      dispatch({
        type: CREATE_ENROLMENT_COMMENT_FAILURE,
      });
      throw e;
    }
  };

export const LIST_ENROLMENT_COMMENT_REQUEST =
  "ENROLMENT_REQUEST_COMMENT@LIST_ENROLMENT_COMMENT_REQUEST";
export const LIST_ENROLMENT_COMMENT_SUCCESS =
  "ENROLMENT_REQUEST_COMMENT@LIST_ENROLMENT_COMMENT_SUCCESS";
export const LIST_ENROLMENT_COMMENT_FAILURE =
  "ENROLMENT_REQUEST_COMMENT@LIST_ENROLMENT_COMMENT_FAILURE";

export const listEnrolmentRequestComments = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_ENROLMENT_COMMENT_REQUEST,
  });
  try {
    const commentData = await http.get("/enrolment-request-comments", {
      params,
    });
    dispatch({
      type: LIST_ENROLMENT_COMMENT_SUCCESS,
      payload: {
        listData: commentData.data,
      },
    });
    return commentData;
  } catch (e) {
    dispatch({
      type: LIST_ENROLMENT_COMMENT_FAILURE,
    });
  }
};

// export const LIST_PREDEFINED_COMMENT_REQUEST = 'COMMENT@LIST_PREDEFINED_COMMENT_REQUEST';
// export const LIST_PREDEFINED_COMMENT_SUCCESS = 'COMMENT@LIST_PREDEFINED_COMMENT_SUCCESS';
// export const LIST_PREDEFINED_COMMENT_FAILURE = 'COMMENT@LIST_PREDEFINED_COMMENT_FAILURE';

// export const listPredefinedComments = () => async dispatch => {
//   const http = getAxiosInstance();
//   dispatch({
//     type: LIST_PREDEFINED_COMMENT_REQUEST,
//   });
//   try{
//     const predefinedCommentData = await http.get('/predefined-responses',);
//     dispatch({
//       type: LIST_PREDEFINED_COMMENT_SUCCESS,
//       payload: {
//         listData: predefinedCommentData.data,
//       },
//     });
//   }catch(e){
//     dispatch({
//       type: LIST_PREDEFINED_COMMENT_FAILURE,
//     });
//   }
// };

export const FETCH_ENROLMENT_COMMENT_REQUEST =
  "ENROLMENT_REQUEST_COMMENT@FETCH_ENROLMENT_COMMENT_REQUEST";
export const FETCH_ENROLMENT_COMMENT_SUCCESS =
  "ENROLMENT_REQUEST_COMMENT@FETCH_ENROLMENT_COMMENT_SUCCESS";
export const FETCH_ENROLMENT_COMMENT_FAILURE =
  "ENROLMENT_REQUEST_COMMENT@FETCH_ENROLMENT_COMMENT_FAILURE";

export const fetchEnrolmentRequestComment = (commentId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_ENROLMENT_COMMENT_REQUEST,
  });
  try {
    const commentData = await http.get(
      `/enrolment-request-comments/${commentId}`
    );
    dispatch({
      type: FETCH_ENROLMENT_COMMENT_SUCCESS,
      payload: {
        commentData: commentData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: FETCH_ENROLMENT_COMMENT_FAILURE,
    });
  }
};

export const UPDATE_ENROLMENT_COMMENT_REQUEST =
  "ENROLMENT_REQUEST_COMMENT@UPDATE_ENROLMENT_COMMENT_REQUEST";
export const UPDATE_ENROLMENT_COMMENT_SUCCESS =
  "ENROLMENT_REQUEST_COMMENT@UPDATE_ENROLMENT_COMMENT_SUCCESS";
export const UPDATE_ENROLMENT_COMMENT_FAILURE =
  "ENROLMENT_REQUEST_COMMENT@UPDATE_ENROLMENT_COMMENT_FAILURE";

export const updateEnrolmentRequestComment =
  (id, commentData) => async (dispatch) => {
    const http = getAxiosInstance();
    dispatch({
      type: UPDATE_ENROLMENT_COMMENT_REQUEST,
    });
    try {
      const data = await http.put(
        `/enrolment-request-comments/${id}`,
        commentData
      );
      dispatch({
        type: UPDATE_ENROLMENT_COMMENT_SUCCESS,
        payload: {
          commentData: data,
        },
      });
    } catch (e) {
      dispatch({
        type: UPDATE_ENROLMENT_COMMENT_FAILURE,
      });
    }
  };

export const DELETE_ENROLMENT_COMMENT_REQUEST =
  "ENROLMENT_REQUEST_COMMENT@DELETE_ENROLMENT_COMMENT_REQUEST";
export const DELETE_ENROLMENT_COMMENT_SUCCESS =
  "ENROLMENT_REQUEST_COMMENT@DELETE_ENROLMENT_COMMENT_SUCCESS";
export const DELETE_ENROLMENT_COMMENT_FAILURE =
  "ENROLMENT_REQUEST_COMMENT@DELETE_ENROLMENT_COMMENT_FAILURE";

export const deleteEnrolmentRequestComment = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_ENROLMENT_COMMENT_REQUEST,
  });
  try {
    const data = await http.delete(`/enrolment-request-comments/${id}`);
    dispatch({
      type: DELETE_ENROLMENT_COMMENT_SUCCESS,
      payload: {
        commentId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: DELETE_ENROLMENT_COMMENT_FAILURE,
    });
  }
};
