import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { LOGIN_MODAL_ID } from "../consts/modalIds";
import { openModal } from "../middleware/actions/modal";
import { getUserDetails } from "../middleware/selectors/user";

const withAuthentication = (
  ChildComponent,
  requiresLogin = true,
  requiresCompletion = true
) => {
  const WithAuthComponent = ({ userData: userDetails, openModal, ...props }) => {
    const history = useHistory();

    useEffect(() => {
      if (!userDetails.isFetched && !userDetails.isFetching && requiresLogin) {
        history.push("/");
        openModal(LOGIN_MODAL_ID);
      } else if (
        userDetails.isFetched &&
        userDetails.userData &&
        !userDetails.userData.profileComplete &&
        requiresCompletion
      ) {
        history.push("/completeProfile");
      }
    }, [userDetails]);
    return <ChildComponent {...props} />;
  };

  const mapStateToProps = (state) => ({
    userData: getUserDetails(state),
  });

  const mapDispatchToProps = {
    openModal,
  };

  return connect(mapStateToProps, mapDispatchToProps)(WithAuthComponent);
};

export default withAuthentication;
