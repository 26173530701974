import InfoIcon from "../img/info.svg";

const InfoBarEnrolmentRequest = ({
  currentTab,
  pupilTypes,
  setCurrentTab,
  cancelationFlow,
  resetTabsInfo,
  isRegularFlowStarted,
}) => {
  const setTab = (tabOrder) => {
    setCurrentTab(tabOrder);
    resetTabsInfo = { resetTabsInfo };
  };

  return (
    <div className="container flex items-center justify-center space-x-5 flex-wrap mb-7">
      {pupilTypes.map((type, i) => {
        // Disable the first tab when it is cancelation flow, -- deprecated
        // or disable the second and third tab when it is not cancelation flow.
        const disabled = isRegularFlowStarted && i > 0;

        return (
          <button
            className={`w-auto inline-flex items-center justify-center border-2 border-white text-base md:text-lg font-semibold rounded-full py-2 px-4 mb-4 focus:outline-none ${
              currentTab === i
                ? "text-white bg-blue"
                : "text-lightGray2 bg-lightGray1 focus:outline-none"
            }`}
            onClick={() => setTab(i)}
            disabled={disabled}
            key={type}
          >
            <span>{type}</span>
          </button>
        );
      })}
      {currentTab === 0 && (
        <div className="flex flex-col px-5 md:px-0 mt-4 mb-1">
          <div className="inline-flex flex-row justify-center w-3/5 m-auto">
            <img src={InfoIcon} width="45" height="auto" />
            <p className="text-sm text-left text-gray-400 md:text-xl font-light mb-2 ml-6">
              Се упишува ученик кој е училишен обврзник согласно утврдената
              реонизација донесена од Советот на Општина.
            </p>
          </div>
          <h4 className="text-sm text-center md:text-2xl font-bold uppercase mt-10">
            ПОТВРДИ ГО ТЕРМИНОТ ЗА ОПСЕРВАЦИЈА НА ДЕТЕ И РОДИТЕЛ/СТАРАТЕЛ
          </h4>
        </div>
      )}
      {currentTab === 1 && (
        <div className="flex flex-col px-5 md:px-0 mt-4 mb-1">
          <div className="inline-flex flex-row justify-center w-3/5 m-auto">
            <img src={InfoIcon} width="45" height="auto" />
            <p className="text-sm text-left text-gray-400 md:text-xl font-light mb-2 ml-6">
              Се упишува ученик кој согласно местото на живеење (по лична карта
              или друг доказ) е училишен обврзник на реонизацијата донесена од
              Советот на Општина.
            </p>
          </div>
          <h4 className="text-sm text-center md:text-2xl font-bold uppercase my-2">
            ОДБЕРИ СЛОБОДЕН ТЕРМИН ЗА ОПСЕРВАЦИЈА НА ДЕТЕ И РОДИТЕЛ/СТАРАТЕЛ
          </h4>
        </div>
      )}
      {currentTab === 2 && (
        <div className="flex flex-col px-5 md:px-0 mt-4 mb-1">
          <div className="inline-flex flex-row justify-center w-3/5 m-auto">
            <img src={InfoIcon} width="45" height="auto" />
            <p className="text-sm text-left text-gray-400 md:text-xl font-light mb-2 ml-6">
              Се упишува ученик, нереонски училишен обврзник, по доставено
              барање од страна на родителите (по редослед на пријавување до
              пополнување на бројот).
            </p>
          </div>
          <h4 className="text-sm text-center md:text-2xl font-bold uppercase my-2">
            ОДБЕРИ СЛОБОДЕН ТЕРМИН ЗА ОПСЕРВАЦИЈА НА ДЕТЕ И РОДИТЕЛ/СТАРАТЕЛ
          </h4>
        </div>
      )}
    </div>
  );
};

export default InfoBarEnrolmentRequest;
