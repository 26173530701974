import getAxiosInstance from '../config/http';

export const CREATE_SUBVENTION_COMMENT_REQUEST = "SUBVENTION_REQUEST_COMMENT@CREATE_SUBVENTION_COMMENT_REQUEST";
export const CREATE_SUBVENTION_COMMENT_SUCCESS = "SUBVENTION_REQUEST_COMMENT@CREATE_SUBVENTION_COMMENT_SUCCESS";
export const CREATE_SUBVENTION_COMMENT_FAILURE = "SUBVENTION_REQUEST_COMMENT@CREATE_SUBVENTION_COMMENT_FAILURE";

export const createSubventionRequestComment = (file, commentData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_SUBVENTION_COMMENT_REQUEST,
  });
  try {
    const formData = new FormData();
    formData.append("files.files", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(commentData));
    const { data } = await http.post(
      '/subvention-request-comments',
      formData
    );
    dispatch({
      type: CREATE_SUBVENTION_COMMENT_SUCCESS,
      payload: {
        commentData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: CREATE_SUBVENTION_COMMENT_FAILURE,
    });
    throw e;
  }
};

export const LIST_SUBVENTION_COMMENT_REQUEST = 'SUBVENTION_REQUEST_COMMENT@LIST_SUBVENTION_COMMENT_REQUEST';
export const LIST_SUBVENTION_COMMENT_SUCCESS = 'SUBVENTION_REQUEST_COMMENT@LIST_SUBVENTION_COMMENT_SUCCESS';
export const LIST_SUBVENTION_COMMENT_FAILURE = 'SUBVENTION_REQUEST_COMMENT@LIST_SUBVENTION_COMMENT_FAILURE';

export const listSubventionRequestComments = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_SUBVENTION_COMMENT_REQUEST,
  });
  try{
    const commentData = await http.get('/subvention-request-comments', {params});
    dispatch({
      type: LIST_SUBVENTION_COMMENT_SUCCESS,
      payload: {
        listData: commentData.data,
      },
    });
    return commentData;
  }catch(e){
    dispatch({
      type: LIST_SUBVENTION_COMMENT_FAILURE,
    });
  }
};

// export const LIST_PREDEFINED_COMMENT_REQUEST = 'COMMENT@LIST_PREDEFINED_COMMENT_REQUEST';
// export const LIST_PREDEFINED_COMMENT_SUCCESS = 'COMMENT@LIST_PREDEFINED_COMMENT_SUCCESS';
// export const LIST_PREDEFINED_COMMENT_FAILURE = 'COMMENT@LIST_PREDEFINED_COMMENT_FAILURE';

// export const listPredefinedComments = () => async dispatch => {
//   const http = getAxiosInstance();
//   dispatch({
//     type: LIST_PREDEFINED_COMMENT_REQUEST,
//   });
//   try{
//     const predefinedCommentData = await http.get('/predefined-responses',);
//     dispatch({
//       type: LIST_PREDEFINED_COMMENT_SUCCESS,
//       payload: {
//         listData: predefinedCommentData.data,
//       },
//     });
//   }catch(e){
//     dispatch({
//       type: LIST_PREDEFINED_COMMENT_FAILURE,
//     });
//   }
// };


export const FETCH_SUBVENTION_COMMENT_REQUEST = 'SUBVENTION_REQUEST_COMMENT@FETCH_SUBVENTION_COMMENT_REQUEST';
export const FETCH_SUBVENTION_COMMENT_SUCCESS = 'SUBVENTION_REQUEST_COMMENT@FETCH_SUBVENTION_COMMENT_SUCCESS';
export const FETCH_SUBVENTION_COMMENT_FAILURE = 'SUBVENTION_REQUEST_COMMENT@FETCH_SUBVENTION_COMMENT_FAILURE';

export const fetchSubventionRequestComment = commentId => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_SUBVENTION_COMMENT_REQUEST,
  });
  try{
    const commentData = await http.get(`/subvention-request-comments/${commentId}`);
    dispatch({
      type: FETCH_SUBVENTION_COMMENT_SUCCESS,
      payload: {
        commentData: commentData.data,
      }
    });
  } catch(e){
    dispatch({
      type: FETCH_SUBVENTION_COMMENT_FAILURE,
    });
  };
};

export const UPDATE_SUBVENTION_COMMENT_REQUEST = 'SUBVENTION_REQUEST_COMMENT@UPDATE_SUBVENTION_COMMENT_REQUEST';
export const UPDATE_SUBVENTION_COMMENT_SUCCESS = 'SUBVENTION_REQUEST_COMMENT@UPDATE_SUBVENTION_COMMENT_SUCCESS';
export const UPDATE_SUBVENTION_COMMENT_FAILURE = 'SUBVENTION_REQUEST_COMMENT@UPDATE_SUBVENTION_COMMENT_FAILURE';

export const updateSubventionRequestComment = (id, commentData) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_SUBVENTION_COMMENT_REQUEST,
  });
  try{
    const data = await http.put(`/subvention-request-comments/${id}`, commentData);
    dispatch({
      type: UPDATE_SUBVENTION_COMMENT_SUCCESS,
      payload: {
        commentData: data,
      }
    });
  } catch(e){
    dispatch({
      type: UPDATE_SUBVENTION_COMMENT_FAILURE,
    });
  };
};

export const DELETE_SUBVENTION_COMMENT_REQUEST = 'SUBVENTION_REQUEST_COMMENT@DELETE_SUBVENTION_COMMENT_REQUEST';
export const DELETE_SUBVENTION_COMMENT_SUCCESS = 'SUBVENTION_REQUEST_COMMENT@DELETE_SUBVENTION_COMMENT_SUCCESS';
export const DELETE_SUBVENTION_COMMENT_FAILURE = 'SUBVENTION_REQUEST_COMMENT@DELETE_SUBVENTION_COMMENT_FAILURE';

export const deleteSubventionRequestComment = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_SUBVENTION_COMMENT_REQUEST,
  });
  try{
    const data = await http.delete(`/subvention-request-comments/${id}`);
    dispatch({
      type: DELETE_SUBVENTION_COMMENT_SUCCESS,
      payload: {
        commentId:id,
      }
    });
    return data;
  } catch(e){
    dispatch({
      type: DELETE_SUBVENTION_COMMENT_FAILURE,
    });
  };
};
